import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, isRef as _isRef } from "vue"
import _imports_0 from '@/assets/images/emoji-open.svg'


const _hoisted_1 = { class: "template-form" }
const _hoisted_2 = { class: "template-form__redactor" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = {
  key: 0,
  class: "template-form__variables"
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "template-form__footer" }

import { useStore } from "vuex";
import inputCheck from "@/components/UI/inputs/input-check.vue";
import inputTypeRadio from "@/components/UI/inputs/input-type-radio.vue";
import emojiButton from "@/components/reactivation/emojiButton.vue";
import { computed, onMounted, ref, watch } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'reactivationTemplateForm',
  setup(__props) {

const store = useStore();

const activeSegment = computed(() => store.state.segments.currentSelectSegment);

const channels = ref(["Push", "WhatsApp"]);

let variablesVisible = ref(false);

const variables = [
  {
    template: "CLIENT_NAME",
    color: "rgb(92, 195, 198)",
    name: "Имя клиента",
    id: 1,
  },
];

let activeChannel = ref("Push");

const textareaRef = ref(null);

const textareaValue = computed({
  get() {
    return store.state.reactivation.reactivationTemplate;
  },
  set(value) {
    store.commit("reactivation/updateReactivationTemplate", value);
  },
});

const formattedText = computed(() => {
  let text = textareaValue.value;
  variables.forEach((variable) => {
    const regex = new RegExp(`%${variable.template}%`, "gi");
    text = text.replace(
      regex,
      `<span class="var" style="color: ${
        variable.color
      };">%${variable.template.toUpperCase()}%</span>`
    );
  });
  return text;
});

watch(textareaValue, (newValue) => {
  variables.forEach((variable) => {
    const regex = new RegExp(`%${variable.template}%`, "gi");
    textareaValue.value = textareaValue.value.replace(
      regex,
      `%${variable.template.toUpperCase()}%`
    );
  });
});

function sanitizeInput() {
  textareaValue.value = textareaValue.value.replace(/</g, "");
}

function addVariable(template) {
  const textarea = textareaRef.value;
  if (!textarea) return;

  const cursorPosition = textarea.selectionStart;
  const textBefore = textareaValue.value.substring(0, cursorPosition);
  const textAfter = textareaValue.value.substring(cursorPosition);
  textareaValue.value = textBefore + `%${template.toUpperCase()}%` + textAfter;
  variablesVisible.value = false;
  setTimeout(() => {
    if (textarea) {
      textarea.setSelectionRange(
        cursorPosition + template.length + 2,
        cursorPosition + template.length + 2
      );
      textarea.focus();
    }
  }, 0);
}

function addEmoji(emoji) {
  const textarea = textareaRef.value;
  if (!textarea) return;

  const cursorPosition = textarea.selectionStart;
  const textBefore = textareaValue.value.substring(0, cursorPosition);
  const textAfter = textareaValue.value.substring(cursorPosition);
  textareaValue.value = textBefore + emoji.emoji + textAfter;
  setTimeout(() => {
    if (textarea) {
      const newCursorPosition = cursorPosition + emoji.emoji.length;
      textarea.setSelectionRange(newCursorPosition, newCursorPosition);
      textarea.focus();
    }
  }, 0);
}

function clearEditor() {
  textareaValue.value = ""; // Clear the textarea value
}

onMounted(() => {
  const textarea = textareaRef.value;
  if (textarea) {
    textarea.addEventListener("input", function () {
      this.style.height = "auto";
      this.style.height = Math.max(this.scrollHeight, 270) + "px";
    });
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[4] || (_cache[4] = _createElementVNode("div", { class: "text-small" }, "Введите текст сообщения", -1)),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(), _createElementBlock("div", {
        class: "template-form__redaction",
        key: _ctx.channel
      }, [
        _createElementVNode("div", {
          class: "template-form__text",
          innerHTML: formattedText.value,
          style: {"white-space":"pre-wrap"}
        }, null, 8, _hoisted_3),
        _withDirectives(_createElementVNode("textarea", {
          ref_key: "textareaRef",
          ref: textareaRef,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((textareaValue).value = $event)),
          onInput: sanitizeInput
        }, null, 544), [
          [_vModelText, textareaValue.value]
        ])
      ])),
      _createVNode(_Transition, { name: "fade" }, {
        default: _withCtx(() => [
          (_unref(variablesVisible))
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList(variables, (variable) => {
                  return _createElementVNode("button", {
                    class: "template-form__variable",
                    key: variable.id,
                    style: _normalizeStyle(`background-color:${variable.color} `),
                    onClick: ($event: any) => (addVariable(variable.template))
                  }, _toDisplayString(variable.name), 13, _hoisted_5)
                }), 64))
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_6, [
      _cache[3] || (_cache[3] = _createElementVNode("button", { class: "template-form__btn _in-develop" }, [
        _createElementVNode("input", { type: "file" }),
        _createTextVNode(" Прикрепить файл ")
      ], -1)),
      _createElementVNode("button", {
        class: "template-form__btn",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_isRef(variablesVisible) //@ts-ignore
 ? variablesVisible.value = !_unref(variablesVisible) : variablesVisible = !_unref(variablesVisible)))
      }, " Переменные "),
      _createVNode(emojiButton, {
        class: "template-form__btn _emoji",
        onEmojiSelected: addEmoji
      }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createElementVNode("img", {
            src: _imports_0,
            alt: ""
          }, null, -1)
        ])),
        _: 1
      }),
      _createElementVNode("button", {
        class: "template-form__btn",
        onClick: clearEditor
      }, "Очистить")
    ])
  ]))
}
}

})