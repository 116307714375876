import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import mainPage from '@/pages/mainPage.vue'
import reportsPage from '@/pages/reportsPage.vue'
import tarifPage from '@/pages/tariffsPage.vue'
import reactivationPage from '@/pages/reactivationPage.vue'
import whatsAppPage from '@/pages/whatsAppPage.vue'
import presentationPage from '@/pages/presentationPage.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "main",
    component: mainPage,
  },
  {
    path: "/reports",
    name: "reports",
    component: reportsPage,
  },
  {
    path: '/tariffs',
    name: 'tariffs',
    component: tarifPage
  },
  {
    path: '/presentation',
    name: 'presentation',
    component: presentationPage
  },
  {
    path: '/reactivation',
    name: 'reactivation',
    component: reactivationPage
  },
  {
    path: '/whatsapp',
    name: 'whatsapp',
    component: whatsAppPage
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
