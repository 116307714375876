import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "zebra-table" }
const _hoisted_2 = { class: "zebra-table__header" }
const _hoisted_3 = { class: "zebra-table__rows" }
const _hoisted_4 = ["id"]
const _hoisted_5 = ["innerHTML"]

import { useStore } from "vuex";

type TypeProps = {
  headerElements: string[];
  elements: any[];
  gridColumns: string;
  elementsOrder: string[];
  idFieldName?: string,
};


export default /*@__PURE__*/_defineComponent({
  __name: 'zebraTable',
  props: {
    headerElements: {},
    elements: {},
    gridColumns: {},
    elementsOrder: {},
    idFieldName: {}
  },
  setup(__props: any) {

const props = __props;

function isObject(value) {
  return value !== null && typeof value === "object" && !Array.isArray(value);
}
function getElementValue(element, elementInfo) {
  if (isObject(elementInfo)) {
    const result = elementInfo.func(element[elementInfo.value])

    if (typeof result === 'boolean') {
      return `<div class="${result? 'i-success': 'i-failed'}"></div>`
    } else {
      return result
    }
  } else {
    return element[elementInfo]
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "zebra-table__row",
        style: _normalizeStyle(`grid-template-columns:${_ctx.gridColumns};`)
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headerElements, (headerElement) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "zebra-table__col",
            key: headerElement
          }, _toDisplayString(headerElement), 1))
        }), 128))
      ], 4)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.elements, (element, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "zebra-table__row",
          id: _ctx.idFieldName + `_` + element[_ctx.idFieldName],
          style: _normalizeStyle(`grid-template-columns:${_ctx.gridColumns};`),
          key: index
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.elementsOrder, (elementInfo) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "zebra-table__col",
              key: elementInfo,
              innerHTML: getElementValue(element, elementInfo)
            }, null, 8, _hoisted_5))
          }), 128))
        ], 12, _hoisted_4))
      }), 128))
    ])
  ]))
}
}

})