import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed } from "vue";

import { useStore } from "vuex";
import { ISegment } from "@/types/typesOfApi.ts";

import baseSegment from "./baseSegment.vue";

type typeProps = {
  segment: ISegment;
};

const maxX = 160;
const maxY = 5;


export default /*@__PURE__*/_defineComponent({
  __name: 'segmentsItem',
  props: {
    segment: {}
  },
  setup(__props: any) {

const store = useStore();

const isTableLoading = computed(() => store.state.segments.isTableLoading);

/* eslint-disable-next-line */
const props = __props;

const pW = 100 / maxX; // кол-во процентов на 1 деление по горизонтали
const pH = 100 / maxY; // кол-во процентов на 1 деление по вертикали

const MaR = computed(() => props.segment.max_recency || 0);
const MiR = computed(() => props.segment.min_recency || 0);

const MaF = computed(() => props.segment.max_frequency || 0);
const MiF = computed(() => props.segment.min_frequency || 0);

const difFrequency = MaF.value - MiF.value || 1;
const difRecency = MaR.value - MiR.value || 1;

const bottom = computed(() => MiF.value * pH);
const left = computed(() => MiR.value * pW);
const height = computed(() => difFrequency * pH);
const width = computed(() => difRecency * pW);

const bgColor = computed(() => props.segment.background_color);

const borderRaduis = computed(() => {
  if (MaF.value === maxY && MiR.value === 0) {
    return "_border-top-left-radius";
  } else if (MiF.value === 0 && MiR.value === 0) {
    return "_border-bottom-left-radius";
  } else if (MaF.value === maxY && MaR.value === maxX) {
    return "_border-top-right-radius";
  } else if (MiF.value === 0 && MaR.value === maxX) {
    return "_border-bottom-right-radius";
  } else {
    return "";
  }
});

const styleStr = computed(
  () =>
    `
    bottom: calc(${bottom.value}% + 5px); 
    left: ${left.value}%;
    height: calc(${height.value}% - 10px);
    width: calc(${width.value}% - 10.7px);
    background-color: ${bgColor.value};
    `
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(baseSegment, {
    class: _normalizeClass(["segments-item", [borderRaduis.value, { _hover: !isTableLoading.value }]]),
    segment: props.segment,
    style: _normalizeStyle(styleStr.value)
  }, null, 8, ["class", "segment", "style"]))
}
}

})