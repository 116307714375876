export const templateAdvices = {
  title: `Структура маркетинговых сообщений, используемая самыми продвинутыми маркетологами, фокусируется на нескольких ключевых элементах для максимального вовлечения и конверсии:`,
  footerText: `<span style='font-style:italic'>Используйте эту структуру, чтобы шаг за шагом создать свой текст.</span> <br> Она поможет вам написать эффективное сообщение, которое привлечет внимание и побудит к действию.`,
  list: [
    {
      name: "Интригующее начало:",
      text: `Задайте вопрос или предложите что-то интересное, чтобы клиент захотел узнать больше.`,
      example:
        '"Готовы к приятному сюрпризу?" или "Эксклюзивное предложение для вас!"',
    },
    {
      name: "Главное предложение:",
      text: `Чётко обозначьте выгоду предложения.`,
      example: '"Только сегодня — скидка 20% на все услуги."',
    },
    {
      name: "Подробности:",
      text: `Раскройте детали и условия, чтобы клиент понял ценность предложения.`,
      example: '"Акция действует до конца недели, выбирайте удобное время."',
    },
    {
      name: "Эмоциональный отклик:",
      text: `Покажите заботу и внимание к клиенту.`,
      example:
        '"Мы знаем, как важно время для себя, и готовы помочь вам расслабиться."',
    },
    {
      name: "Призыв к действию:",
      text: `Упростите следующий шаг и сделайте его максимально понятным.`,
      example: "\"Ответьте 'Да', и мы сразу забронируем время.\"",
    },
    {
      name: "Благодарность:",
      text: `Завершите сообщение вежливо и выразите признательность.`,
      example: '"Спасибо, что выбираете нас!"',
    },
  ],
  preLastBlock: {
    title: "Пример push-уведомления:",
    text: '"Готовы к приятному сюрпризу? Скидка 20% — сегодня!"',
  },
  lastBlock: {
    name: "Пример полного текста:",
    text: `"Мы рады поделиться с вами специальным предложением — скидка 20% на первую запись! <br><br>

Акция действует до конца недели, и вы можете забронировать удобное время прямо сейчас. <br><br>

Мы понимаем, как важно уделить время себе и расслабиться, и будем рады сделать ваш визит приятным и комфортным. <br> <br>

Напишите 'Да', и мы свяжемся с вами, чтобы подобрать удобное время и ответить на все вопросы.<br><br>

Спасибо, что выбираете нас! Мы с нетерпением ждём встречи!"
    `,
  },
};
export const sendAdvices = {
  list: [
    {
      name: `Возраст номера`,
      text: `Используйте номер для рассылок, который подключён к WhatsApp не менее 2 месяцев`,
    },
    {
      name: `Не отправляйте ссылки`,
      text: `Избегайте включения ссылок в рассылки, это увеличивает вероятность блокировки.`,
    },
    {
      name: `Персонализация`,
      text: `Используйте переменные для персонализированных сообщений, чтобы повысить доверие клиентов.`,
    },
    {
      name: `Предупреждение о спаме`,
      text: `При первом сообщении от незнакомого номера клиент может нажать "пожаловаться на спам". Придерживайтесь корректной коммуникации.`,
    },
  ],
};
