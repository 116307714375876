<template>
  <div class="segments-presentation">
    <presentationStep
      v-show="currentStep === 0"
      :info="presentationSteps[0]"
    ></presentationStep>
    <presentationStep
      v-show="currentStep === 1"
      :info="presentationSteps[1]"
    ></presentationStep>
    <presentationStep
      v-show="currentStep === 2"
      :info="presentationSteps[2]"
    ></presentationStep>
    <presentationStep
      v-show="currentStep === 3"
      :info="presentationSteps[3]"
    ></presentationStep>
    <presentationStep v-show="currentStep === 4" :info="presentationSteps[4]">
      <p class="fw-500 mb-13px">Тарифы и Новогодние Скидки</p>
      <p class="mb-20px">
        До 31 декабря 2024 года активируйте любой тариф со скидкой до 80%!
      </p>
      <div class="segments-presentation__tariffs mb-20px" v-if="allTariffs">
        <tarifCard
          v-for="tarif in allTariffs"
          :key="tarif"
          :tarif="tarif"
          :type="'short'"
          @goTariffs="goTariffs"
        ></tarifCard>
      </div>
    </presentationStep>

    <div
      class="presentation-control"
      :class="[
        { 'move-top1': currentStep === 1 },
        { 'move-top2': currentStep === 3 },
      ]"
    >
      <div class="presentation-control__step" v-html="currentStepStr"></div>
      <div class="presentation-control__right">
        <div
          class="presentation-control__text font-ital fw-500"
          v-html="currentSupBtnText"
        ></div>
        <div class="presentation-control__btns">
          <button
            class="presentation-control__btn _prev"
            v-if="currentStep > 0"
            @click="prevStep"
          >
            Назад
          </button>
          <button
            class="presentation-control__btn"
            v-html="currentBtnText"
            @click="nextStep"
          ></button>
        </div>

        <a target="_blank" :href="store.state.supportLink" class="blue-link"
          >Что-то непонятно? Напишите нам прямо в WhatsApp</a
        >
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useStore } from "vuex";
import presentationStep from "./presentation-step.vue";

import tarifCard from "@/components/tariffs/tarifCard.vue";
import { computed, ref } from "vue";
import router from "@/router";

const store = useStore();

const allTariffs = computed(() => store.state.tarif.allTariffs);

const additionalInfo = computed(() => store.state.auth.additionalInfo);

let currentStep = ref(0);

function prevStep() {
  if (currentStep.value > 0) {
    currentStep.value--;
  }
}

function nextStep() {
  if (currentStep.value < 4) {
    currentStep.value++;
  } else {
    updateShowedPresentationStatus()
    router.push("/");
  }
}
function goTariffs() {
  updateShowedPresentationStatus()
  router.push("/tariffs");
}

function updateShowedPresentationStatus() {
  store.dispatch('auth/updateShowedPresentationStatus')
}

const presentationSteps = [
  {
    index: 1,
    title: "🎉 Сегменты уже работают над анализом вашего филиала!",
    subtitle: "Это займет всего несколько минут.",
    beforeListsText: `Мы отправили вам уведомление в WhatsApp на номер <span class="fw-500">+${additionalInfo.value.phone}.</span>`,
    firstList: {
      title: "Именно на этот номер мы будем:",
      list: [
        "Отправлять отчеты о работе сервиса.",
        "Отвечать на все ваши вопросы в режиме технической поддержки с 8:00 до 20:00 по московскому времени.",
      ],
    },
    secondList: {
      title: "Чтобы быть уверенными, что вы получите наши сообщения:",
      list: [
        "Напишите нам что-нибудь в ответ на это сообщение, чтобы мы знали, что оно дошло.",
        "Добавьте наш номер в свои контакты, чтобы не пропустить уведомления.",
      ],
    },
    afterListsText: `Если вы хотите сменить номер, напишите об этом в диалог с номера +${additionalInfo.value.phone}. <br>
    Или напрямую в техническую поддержку - <a target="_blank" href="${store.state.supportLink}" class="blue-link">ссылка</a>`,
    supBtnText: "Проверьте, всё ли верно:",
    btnText: "Да, всё верно",
  },
  {
    index: 2,
    title: '<span class="fw-900">⌗</span> Сегментация клиентов',
    subtitle:
      "Узнайте, как распределяются ваши клиенты по активности и частоте визитов.",
    img: "segments-slide.webp",
    supBtnText: "Все понятно?",
    btnText: "Идем дальше..",
  },
  {
    index: 3,
    title: "🔥 Реактивация клиентов, просто как - раз, два, три..",
    subtitle: "Верните холодных клиентов в ваше ядро!",
    firstList: {
      title: "Вам нужно всего лишь:",
      list: [
        "Запустить реактивацию в пару кликов. Настроить рассылки, используя готовые шаблоны сообщений.",
        "Автоматически отслеживать результаты. Система покажет, кто записался, пришел и оплатил.",
        "Получать точные метрики. Видеть движения по каждому сегменту в режиме реального времени.",
      ],
    },
    secondList: {
      title: "Пример использования:",
      list: [
        'Выберите сегмент, например, "В зоне риска".',
        "Настройте реактивацию: выберите канал (WhatsApp), используйте предложенный текст или напишите свой.",
        "Безопасно протестируйте на себе.",
        "Наблюдайте, как клиенты возвращаются: система автоматически обновит данные и покажет эффективность кампании.",
      ],
    },
    img: "segments-slide1.webp",
    supBtnText: "Уже готовы начать?",
    btnText: "Идем дальше..",
  },
  {
    index: 4,
    title: "💬 Как отправляются сообщения через ваш SMS-агрегатор?",
    subtitle:
      "Сегменты устанавливаются поверх вашего провайдера и не нарушают его работу!",
    firstList: {
      title: "Почему через ваш SMS-агрегатор?",
      list: [
        "Сервис Сегменты интегрируется с вашим каналом связи автоматически, чтобы гарантировать, что каждая отправка сообщений соответствует вашим правилам и бюджету.",
        "Мы не берём дополнительных комиссий за передачу данных в ваш агрегатор.",
        "Все все настройки для массовой рассылки и очередей отправки уточняйте у вашего Агрегатора.",
      ],
    },
    secondList: {
      title: "Ваши сообщения не отправляются прямо из YCLIENTS в WhatsApp?",
      list: [
        "Возможно ваш интегратор имеет специфические настройки. Уточните как именно отправить сообщение клиенту по каналу СМС. ",
        "Отключена интеграция в разделе Уведомления. Отключите и подключите заново чтобы обновить настройки. ",
        "Протестируйте отправку сообщения из карточки клиента. Отправьте сообщение по каналу СМС.",
      ],
    },
    img: "segments-slide2.webp",
    supBtnText: "Уже готовы начать?",
    btnText: "Последний шаг..",
  },
  {
    index: 5,
    title: '🎉 Добро пожаловать в "Сегменты"!',
    subtitle:
      "Ваша аналитика, автоматизация и эффективность — за несколько рублей в день!",
    supBtnText: "Благодарим за доверие",
    btnText: "Начать тестирование",
  },
];

const currentSupBtnText = computed(
  () => presentationSteps[currentStep.value].supBtnText
);
const currentBtnText = computed(
  () => presentationSteps[currentStep.value].btnText
);

const currentStepStr = computed(() => {
  return `Шаг ${currentStep.value + 1} из ${presentationSteps.length}`;
});
</script>

<style lang="scss" scoped>
.segments-presentation {
  position: relative;
  background: #fff;
  max-width: 1100px;
  padding: 50px 130px 37px 60px;
  border-radius: 11px;

  filter: drop-shadow(0 0 10px #33333386);

  &__tariffs {
    display: flex;
    gap: 40px;
  }
}
.presentation-control {
  display: flex;
  justify-content: space-between;
  align-items: end;
  position: relative;

  &.move-top1 {
    top: -174px;
    margin-bottom: -160px;
  }
  &.move-top2 {
    top: -85px;
    margin-bottom: -85px;
  }

  &__step {
    color: rgba(0, 0, 0, 0.2);
  }

  &__right {
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: 15px;
    position: relative;
    right: -20px;
    top: 15px;
  }

  &__text {
    position: relative;
  }

  &__btns {
    display: flex;
    align-items: center;
    gap: 10px;

    position: relative;
    right: -50px;
  }

  &__btn {
    background: #cdf3ff;
    min-width: 197px;
    padding: 18px 20px;
    text-align: center;
    border-radius: 7px;
    position: relative;
    left: -20px;
    cursor: pointer;
    transition: 0.1s;

    &._prev {
      min-width: 0;
    }

    &:hover {
      background: rgba(205, 243, 255, 0.5);
    }
  }

  .blue-link {
    border: none;
    font-size: 10px;
    position: relative;
  }
}
</style>
