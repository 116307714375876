import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { EmojiButton } from "@joeattardi/emoji-button";
import { onMounted, ref } from "vue";

// Объявляем событие emit

export default /*@__PURE__*/_defineComponent({
  __name: 'emojiButton',
  emits: ["emoji-selected"],
  setup(__props, { emit: __emit }) {

const emit = __emit;

const inputValue = ref("");
const picker = ref(null);

onMounted(() => {
  picker.value = new EmojiButton();
  picker.value.on("emoji", (emoji) => {
    // Вызываем событие emit при выборе смайлика
    console.log(emoji.emoji)
    emit("emojiSelected", emoji);
  });
});

function toggleEmojiPicker(event) {
  picker.value.togglePicker(event.currentTarget);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", { onClick: toggleEmojiPicker }, [
    _renderSlot(_ctx.$slots, "default")
  ]))
}
}

})