import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, unref as _unref } from "vue"

const _hoisted_1 = { class: "last-update-timer" }
const _hoisted_2 = { class: "last-update" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = ["innerHTML"]

import { ref, watch, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";


export default /*@__PURE__*/_defineComponent({
  __name: 'lastUpdateTimer',
  props: {
  bgTimer: String,
  interval: Number,
  updateFunction: Function,
  isTimerRunning: {
    type: Boolean,
    default: false,
  },
},
  setup(__props) {

const props = __props;

const store = useStore();

const displayValue = ref<number | null>(null);
let computedString = ref<string>("");

function updateString() {
  if (typeof props.updateFunction === "function") {
    props.updateFunction().then((statusStr) => {
      console.log(statusStr);
      if (statusStr) {
        computedString.value = statusStr;
      }
      store.commit("setMainLoader", false);
    });
  }
}

let timer = null;

function isPageVisible() {
  return document.visibilityState === "visible";
}

watch(
  () => props.isTimerRunning,
  function (newVal) {
    if (newVal) {
      if (isPageVisible()) {
        updateString();
      }
      startTimer();
    } else {
      stopTimer();
    }
  }
);

function startTimer() {
  if (timer) return;

  displayValue.value = props.interval / 1000;

  timer = setInterval(() => {
    if (displayValue.value !== null) {
      displayValue.value -= 1;
    }

    if (displayValue.value <= 0) {
      stopTimer();
      if (isPageVisible()) {
        updateString();
        startTimer();
      }
    }
  }, 1000);
}

function stopTimer() {
  if (timer) {
    clearInterval(timer);
    timer = null;
  }
  displayValue.value = null;
}

function handleVisibilityChange() {
  if (isPageVisible() && props.isTimerRunning) {
    if (!timer) {
      updateString();
      startTimer();
    }
  }
}

function refresh() {
  store.commit("setMainLoader", true);
  stopTimer();
  updateString();
  startTimer();
}

onMounted(() => {
  document.addEventListener("visibilitychange", handleVisibilityChange);
  if (props.isTimerRunning) {
    if (isPageVisible()) {
      updateString();
    }
    startTimer();
  }
});

onUnmounted(() => {
  document.removeEventListener("visibilitychange", handleVisibilityChange);
  stopTimer();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "last-update__timer",
        style: _normalizeStyle(`background-color:${__props.bgTimer}`)
      }, [
        (__props.isTimerRunning)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(displayValue.value), 1))
          : _createCommentVNode("", true)
      ], 4),
      _createElementVNode("div", {
        class: "last-update__str",
        innerHTML: _unref(computedString)
      }, null, 8, _hoisted_4)
    ]),
    _createElementVNode("button", {
      class: "refresh",
      onClick: refresh
    }, "Обновить")
  ]))
}
}

})