import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "start-settings" }
const _hoisted_2 = { class: "tit-h3 bold" }
const _hoisted_3 = { class: "start-settings__steps" }

import stepOfferService from "@/components/main-page/start-steps/stepOfferService.vue";
import stepSetConsultationContact from "@/components/main-page/start-steps/stepSetConsultationContact.vue";
import selectElement from "@/components/UI/inputs/select-elements.vue";
import { ref, reactive, computed } from "vue";
import { useStore } from "vuex";


export default /*@__PURE__*/_defineComponent({
  __name: 'startSettings',
  setup(__props) {

const store = useStore()

const mainPageStep = computed<number>(() => store.state.mainPageStep);

const usersList = ref<TypeFilialUserData[]>([
  {
    name: "Пользователь 1",
    value: false,
    id: 1,
  },
  {
    name: "Пользователь 2",
    value: false,
    id: 2,
  },
  {
    name: "Пользователь 3",
    value: false,
    id: 3,
  },
]);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[5] || (_cache[5] = _createElementVNode("div", {
      class: "tit-h1",
      style: {"align-self":"start"}
    }, [
      _createTextVNode(" Расчитываем Сегменты "),
      _createElementVNode("span", { class: "pints" }, "...")
    ], -1)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h3", _hoisted_2, " Завершите настройку - Шаг " + _toDisplayString(mainPageStep.value+=1) + " из 3 ", 1),
      _createElementVNode("div", _hoisted_3, [
        (mainPageStep.value === 0)
          ? (_openBlock(), _createBlock(selectElement, {
              key: 0,
              modelValue: usersList.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((usersList).value = $event))
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createElementVNode("div", { class: "subtitle" }, "Кто будет получать отчеты на WhatsApp ?", -1)
              ])),
              _: 1
            }, 8, ["modelValue"]))
          : (mainPageStep.value === 1)
            ? (_openBlock(), _createBlock(stepOfferService, { key: 1 }))
            : _createCommentVNode("", true),
        (mainPageStep.value === 2)
          ? (_openBlock(), _createBlock(selectElement, {
              key: 2,
              modelValue: usersList.value,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((usersList).value = $event))
            }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createElementVNode("div", { class: "subtitle" }, "С кем выйти на связь для консультации ?", -1)
              ])),
              _: 1
            }, 8, ["modelValue"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("button", {
        class: "btn",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_unref(store).commit('setMainPageStep', mainPageStep.value+=1)))
      }, "Далее")
    ])
  ], 64))
}
}

})