<template>
  <div class="last-update-timer">
    <div class="last-update">
      <div class="last-update__timer" :style="`background-color:${bgTimer}`">
        <span v-if="isTimerRunning">{{ displayValue }}</span>
      </div>
      <div class="last-update__str" v-html="computedString"></div>
    </div>
    <button class="refresh" @click="refresh">Обновить</button>
  </div>
</template>

<script lang="ts" setup>
import { ref, watch, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";

const props = defineProps({
  bgTimer: String,
  interval: Number,
  updateFunction: Function,
  isTimerRunning: {
    type: Boolean,
    default: false,
  },
});

const store = useStore();

const displayValue = ref<number | null>(null);
let computedString = ref<string>("");

function updateString() {
  if (typeof props.updateFunction === "function") {
    props.updateFunction().then((statusStr) => {
      console.log(statusStr);
      if (statusStr) {
        computedString.value = statusStr;
      }
      store.commit("setMainLoader", false);
    });
  }
}

let timer = null;

function isPageVisible() {
  return document.visibilityState === "visible";
}

watch(
  () => props.isTimerRunning,
  function (newVal) {
    if (newVal) {
      if (isPageVisible()) {
        updateString();
      }
      startTimer();
    } else {
      stopTimer();
    }
  }
);

function startTimer() {
  if (timer) return;

  displayValue.value = props.interval / 1000;

  timer = setInterval(() => {
    if (displayValue.value !== null) {
      displayValue.value -= 1;
    }

    if (displayValue.value <= 0) {
      stopTimer();
      if (isPageVisible()) {
        updateString();
        startTimer();
      }
    }
  }, 1000);
}

function stopTimer() {
  if (timer) {
    clearInterval(timer);
    timer = null;
  }
  displayValue.value = null;
}

function handleVisibilityChange() {
  if (isPageVisible() && props.isTimerRunning) {
    if (!timer) {
      updateString();
      startTimer();
    }
  }
}

function refresh() {
  store.commit("setMainLoader", true);
  stopTimer();
  updateString();
  startTimer();
}

onMounted(() => {
  document.addEventListener("visibilitychange", handleVisibilityChange);
  if (props.isTimerRunning) {
    if (isPageVisible()) {
      updateString();
    }
    startTimer();
  }
});

onUnmounted(() => {
  document.removeEventListener("visibilitychange", handleVisibilityChange);
  stopTimer();
});
</script>

<style lang="scss" scoped>
.last-update-timer {
  display: flex;
  gap: 10px;
}
.last-update {
  align-self: flex-start;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  height: 30px;
  min-width: 266px;
  font-size: 10px;
  
  border: solid 1px #cccccc;
  border-radius: 9px;
  padding: 2px;
  padding-right: 5px;

  &__str {
    display: inline-block;
    text-align: right;
    line-height: 20px;
    padding-top: 2px;
  }
  &__timer {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    width: 35px;
    height: 100%;
    border-radius: 6px;
    line-height: 2;

    span {
      transform: translateY(1px);
    }
  }
}

.refresh {
  background-color: #f0f0f0;
    border: none;
    border-radius: 6px;
    padding: 5px 10px;
    padding-top: 7px;
    cursor: pointer;
    font-size: 11px;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: #e0e0e0;
    }
}
</style>
