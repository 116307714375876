<template>
    <segmentsPresentation class="presentation-page"></segmentsPresentation>
</template>

<script lang='ts' setup>
import { useStore } from 'vuex';
import segmentsPresentation from '@/components/presentation/segments-presentation.vue'

const store = useStore();

</script>

<style lang='scss' scoped>
.presentation-page {
  width: 1200px;
  margin: 20px;
}
</style>