import { defineComponent as _defineComponent } from 'vue'
import { vModelText as _vModelText, isRef as _isRef, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withModifiers as _withModifiers, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/images/search-icon.svg'


const _hoisted_1 = ["placeholder"]
const _hoisted_2 = {
  key: 0,
  class: "search-result"
}
const _hoisted_3 = { class: "search-result__row" }
const _hoisted_4 = { class: "search-result__row" }
const _hoisted_5 = {
  key: 1,
  class: "search-result"
}
const _hoisted_6 = { class: "search-result__row" }

import { computed, ref, onMounted, onBeforeUnmount } from "vue";
import { useStore } from "vuex";

/* eslint-disable-next-line */

export default /*@__PURE__*/_defineComponent({
  __name: 'searchClientByPhone',
  emits: ['openSegment'],
  setup(__props, { emit: __emit }) {

const emit = __emit;


const store = useStore();

const modifySegments = computed(() => store.state.segments.modifySegments);

const searchWrap = ref(null);

function phoneFocus() {
  isInputActive.value = true;
}

const phoneNumber = computed({
  get: () => store.state.segments.clients.currentSearchClientPhone,
  set: (newValue) =>
    (store.state.segments.clients.currentSearchClientPhone = newValue),
});

const isTableLoading = computed(() => store.state.segments.isTableLoading);

const formatPhoneNumber = () => {
  phoneNumber.value = phoneNumber.value.replace(/\D/g, "");

  if (phoneNumber.value.startsWith("8")) {
    phoneNumber.value = "7" + phoneNumber.value.slice(1);
  }

  if (phoneNumber.value.length > 14) {
    phoneNumber.value = phoneNumber.value.slice(0, 14);
  }

  if (phoneNumber.value.length > 0) {
    phoneNumber.value = "+" + phoneNumber.value;
  }
};

function seacrhByPhone() {
  store.dispatch("segments/clients/searchClientByPhone");
}

function reset() {
  store.state.segments.clients.currentSearchClient = null;
  phoneNumber.value = "";
}

let isInputActive = ref(false);

const inputPlaceholder = computed(() => {
  return isInputActive.value
    ? "+7 000 000 00 00 "
    : "Найти клиента по телефону";
});

const currentSearchLoading = computed(
  () => store.state.segments.clients.currentSearchLoading
);
const currentSearchClient = computed(
  () => store.state.segments.clients.currentSearchClient
);
const currentSearchClientSegmentId = computed(
  () => currentSearchClient.value.segment_id
);

function getSegmentNameByIndex(index) {
  const numericIndex = Number(index);

  const segments = modifySegments.value;

  for (const key in segments) {
    const segment = segments[key];
    console.log(segment.index,numericIndex )
    if (Number(segment.index) === numericIndex) {
      return segment.name;
    }
  }

  for (const segment of segments.dynamicSegments) {
    if (Number(segment.index) === numericIndex) {
      return segment.name;
    }
  }

  for (const segment of segments.awaitingSegments) {
    if (Number(segment.index) === numericIndex) {
      return segment.name;
    }
  }

  return null;
}

const currentSearchSegment = computed(() => {
  const currentSegmentId = currentSearchClientSegmentId.value;
  if (typeof currentSegmentId === "number") {
    const currentSegmentName = getSegmentNameByIndex(currentSegmentId);
    return currentSegmentName;
  } else {
    return null;
  }
});

function handleClickOutside(event) {
  if (searchWrap.value && !searchWrap.value.contains(event.target)) {
    reset();
  }
}

onMounted(() => {
  document.addEventListener("click", handleClickOutside);
});

onBeforeUnmount(() => {
  document.removeEventListener("click", handleClickOutside);
});

function openSegment() {
  console.log('inn')
  store.state.segments.clients.defaultRecomendationPageClients = currentSearchClient.value.page;
  store.state.segments.clients.recomednationPageClientsShow = true;
  store.state.segments.clients.scrollToClientId = `client_id_` + currentSearchClient.value.client_id;
  
  emit("openSegment", currentSearchClientSegmentId.value);
  // reset()
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "segments-search-wrap",
    ref_key: "searchWrap",
    ref: searchWrap
  }, [
    _createElementVNode("form", {
      class: "segments-search",
      onSubmit: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"]))
    }, [
      _withDirectives(_createElementVNode("input", {
        placeholder: inputPlaceholder.value,
        type: "text",
        class: _normalizeClass(["segments-search__input", { 'loading-block': currentSearchLoading.value || isTableLoading.value }]),
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((phoneNumber).value = $event)),
        onInput: formatPhoneNumber,
        onFocus: phoneFocus,
        onBlur: _cache[1] || (_cache[1] = ($event: any) => (_isRef(isInputActive) //@ts-ignore
 ? isInputActive.value = false : isInputActive = false))
      }, null, 42, _hoisted_1), [
        [_vModelText, phoneNumber.value]
      ]),
      _cache[3] || (_cache[3] = _createElementVNode("img", {
        src: _imports_0,
        alt: "",
        class: "segments-search__icon"
      }, null, -1)),
      _createElementVNode("button", {
        class: "segments-search__btn",
        onClick: seacrhByPhone
      }, "Найти")
    ], 32),
    (currentSearchClient.value?.client_id)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _cache[5] || (_cache[5] = _createElementVNode("div", { class: "search-result__row" }, "Клиент найден!", -1)),
          _createElementVNode("div", _hoisted_3, " id: " + _toDisplayString(currentSearchClient.value.client_id), 1),
          _createElementVNode("div", _hoisted_4, [
            _cache[4] || (_cache[4] = _createTextVNode(" Сегмент: ")),
            _createElementVNode("span", {
              class: "search-result__seg",
              onClick: openSegment
            }, _toDisplayString(currentSearchSegment.value), 1)
          ])
        ]))
      : (currentSearchClient.value?.description)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, _toDisplayString(currentSearchClient.value?.description), 1)
          ]))
        : _createCommentVNode("", true)
  ], 512))
}
}

})