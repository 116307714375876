import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "whatsapp-qr _sleep" }
const _hoisted_2 = { class: "whatsapp-qr__code" }

import { useStore } from "vuex";
import QrcodeVue from "qrcode.vue";
import { computed } from "vue";

type codeStatuses = "sleep" | "success" | "loading" | "error";


export default /*@__PURE__*/_defineComponent({
  __name: 'whatsappQr',
  setup(__props) {

const store = useStore();

const qrStatus = computed<codeStatuses>(() => {
  return "sleep";
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(QrcodeVue, {
        value: "Как вы это отсканировали?)",
        size: 320
      })
    ]),
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "whatsapp-qr__action" }, [
      _createElementVNode("button", { class: "btn _green1 _small" }, "Получить QR код")
    ], -1))
  ]))
}
}

})