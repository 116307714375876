export default function getSegmentByIndex(modifySegments:any, index:number) {
  const allSegments = [
    modifySegments.outflow,
    modifySegments.without_orders,
    ...modifySegments.dynamicSegments,
    ...modifySegments.awaitingSegments,
    modifySegments.hadVisits,
    modifySegments.black_list,
  ];

  return allSegments.find((seg) => seg.index === index);
}