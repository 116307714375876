import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import reactivationItemPage from "@/components/reactivation/reactivationItemPage.vue";
import reactivationTable from "@/components/reactivation/reactivationTable.vue";
import paginationBlock from "@/components/UI/bloks/paginationBlock.vue";
import lastUpdateTimer from "@/components/UI/bloks/lastUpdateTimer.vue";
import getCurrentUpdateDateStr from "@/assets/getCurrentUpdateDateStr";


export default /*@__PURE__*/_defineComponent({
  __name: 'reactivationList',
  setup(__props) {

const store = useStore();

const reactivationList = computed(
  () => store.state.reactivation.reactivationList
);

const isShowWhatsApp = computed(() => store.state.auth.isShowWhatsApp);

let currentActivation = ref(null);

function openActivation(activation) {
  currentActivation.value = activation;
}

function closeReactivationItem() {
  currentActivation.value = null;
}

const currentReactivationsPage = computed(
  () => store.state.reactivation.currentReactivationsPage
);

function getReactivationsByPage(pageNumber) {
  return store
    .dispatch("reactivation/getReactivations", pageNumber)
    .then(() => {
      return getCurrentUpdateDateStr();
    })
    .catch(() => {
      return getCurrentUpdateDateStr();
    });
}

function getReactivationClientsByPage() {
  console.log(currentActivation.value.id);
  return store
    .dispatch("reactivation/getReactivationClientsByPage", {
      reactivationId: currentActivation.value.id,
      page: store.state.reactivation.currentPageClientsByReact,
    })
    .then(() => {
      return getCurrentUpdateDateStr();
    });
}

const currentReactivationFilterStatusId = computed(
  () => store.state.reactivation.currentReactivationFilterStatusId
);

watch(currentReactivationFilterStatusId, () => {
  getReactivationsByPage(currentReactivationsPage.value);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_unref(currentActivation) === null)
      ? (_openBlock(), _createBlock(lastUpdateTimer, {
          key: 0,
          bgTimer: '#fbcd00',
          interval: 60000,
          updateFunction: () => getReactivationsByPage(currentReactivationsPage.value),
          isTimerRunning: true,
          class: "mb-10px"
        }, null, 8, ["updateFunction"]))
      : _createCommentVNode("", true),
    (_unref(currentActivation) === null)
      ? (_openBlock(), _createBlock(paginationBlock, {
          key: 1,
          class: "reactivation-list",
          onGetPage: _cache[1] || (_cache[1] = (pageNumber) => getReactivationsByPage(pageNumber)),
          totalItems: _unref(store).state.reactivation.totalReactivations,
          itemsPerPage: 10,
          defaultPage: 1,
          borderBtns: false,
          hideResultStr: true
        }, {
          elements: _withCtx(() => [
            _createVNode(reactivationTable, {
              onOpenActivation: _cache[0] || (_cache[0] = (reactivation) => openActivation(reactivation))
            })
          ]),
          _: 1
        }, 8, ["totalItems"]))
      : (_unref(currentActivation))
        ? (_openBlock(), _createBlock(reactivationItemPage, {
            key: 2,
            activation: _unref(currentActivation),
            onClose: closeReactivationItem
          }, {
            top: _withCtx(() => [
              _createVNode(lastUpdateTimer, {
                bgTimer: '#fbcd00',
                interval: 60000,
                updateFunction: getReactivationClientsByPage,
                isTimerRunning: true
              })
            ]),
            _: 1
          }, 8, ["activation"]))
        : _createCommentVNode("", true)
  ], 64))
}
}

})