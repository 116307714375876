import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page-sections-header" }
const _hoisted_2 = { class: "page-sections-header__top" }
const _hoisted_3 = { class: "sections-header" }
const _hoisted_4 = { class: "sections-info" }
const _hoisted_5 = { class: "page-sections-header__btns" }
const _hoisted_6 = { class: "page-sections-header__content" }

type typeProps = {
  sectionsColor: Array<string>;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'pageWithSectionsHeader',
  props: {
    sectionsColor: { default: () => ["#fff", "#fff", "#fff"] }
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "top"),
      _createElementVNode("button", {
        class: "btn-type1",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
      }, "Закрыть без изменений")
    ]),
    _createElementVNode("header", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", {
          class: "sections-info__block",
          style: _normalizeStyle(`background-color:${props.sectionsColor[0]}`)
        }, [
          _renderSlot(_ctx.$slots, "header1")
        ], 4),
        _createElementVNode("div", {
          class: "sections-info__block",
          style: _normalizeStyle(`background-color:${props.sectionsColor[1]}`)
        }, [
          _renderSlot(_ctx.$slots, "header2")
        ], 4),
        _createElementVNode("div", {
          class: "sections-info__block",
          style: _normalizeStyle(`background-color:${props.sectionsColor[2]}`)
        }, [
          _renderSlot(_ctx.$slots, "header3")
        ], 4)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _renderSlot(_ctx.$slots, "btns")
    ]),
    _createElementVNode("div", _hoisted_6, [
      _renderSlot(_ctx.$slots, "content")
    ])
  ]))
}
}

})