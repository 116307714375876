import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "segments-header" }
const _hoisted_2 = { class: "segments-header__border" }
const _hoisted_3 = { class: "fw-600" }
const _hoisted_4 = { class: "fw-600" }
const _hoisted_5 = {
  class: "segments-header__info _all base-segment",
  style: `background-color: #fff; border: solid 2px #ABE9DC; min-width: 270px`
}
const _hoisted_6 = { class: "fw-600" }
const _hoisted_7 = { class: "header-legal__row" }

import { useStore } from "vuex";
import inputSwitch from "@/components/UI/inputs/input-switch.vue";
import inputTypeRadio from "@/components/UI/inputs/input-type-radio.vue";
import { computed } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'segmentTableHeader',
  emits: ["setCurrentSegment"],
  setup(__props, { emit: __emit }) {

const store = useStore();

const emit = __emit;

const company_rfm = computed(() => store.state.segments.company_rfm);
const modifySegments = computed(() => store.state.segments.modifySegments);

const isTableLoading = computed(() => store.state.segments.isTableLoading);

const allClients = computed(() => +company_rfm.value.total_clients);

const without_orders = computed(
  () => store.state.segments.modifySegments.without_orders
);

const segmentClients = computed(() => +company_rfm.value.segment_clients);

const predictedIncomeShow = computed(
  () => store.state.segments.predictedIncomeShow
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "segments-header__info _all base-segment _border-radius",
        style: _normalizeStyle(`background-color: #fff; border: solid 2px ${without_orders.value.border_color}`)
      }, [
        _cache[4] || (_cache[4] = _createElementVNode("div", null, "Всего клиентов в базе", -1)),
        _createElementVNode("div", {
          class: _normalizeClass(["base-segment__price", { 'loading-text': isTableLoading.value }])
        }, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(allClients.value.toLocaleString()), 1),
          _cache[3] || (_cache[3] = _createTextVNode(" — 100% "))
        ], 2)
      ], 4),
      _cache[7] || (_cache[7] = _createElementVNode("div", { class: "segments-header__gap" }, "—", -1)),
      _createElementVNode("div", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('setCurrentSegment', without_orders.value))),
        class: _normalizeClass([isTableLoading.value ? '' : '_hover _hoverBorderPlus _border-radius', "segments-header__info _all base-segment"]),
        style: `background-color: #D9D9D9; border: solid 2px #CCCCCC; min-width: 256px`
      }, [
        _cache[5] || (_cache[5] = _createElementVNode("div", null, "Клиенты без визитов", -1)),
        _createElementVNode("div", {
          class: _normalizeClass(["base-segment__price", { 'loading-text': isTableLoading.value }])
        }, [
          _createElementVNode("span", _hoisted_4, _toDisplayString((+without_orders.value.total_clients).toLocaleString()), 1),
          _createTextVNode(" — " + _toDisplayString((+without_orders.value.total_clients_percent).toLocaleString()) + "% ", 1)
        ], 2)
      ], 2),
      _cache[8] || (_cache[8] = _createElementVNode("div", { class: "segments-header__gap" }, [
        _createElementVNode("span", null, "—"),
        _createElementVNode("span", null, "—")
      ], -1)),
      _createElementVNode("div", _hoisted_5, [
        _cache[6] || (_cache[6] = _createElementVNode("div", null, "Сегментировано клиентов", -1)),
        _createElementVNode("div", {
          class: _normalizeClass(["base-segment__price", { 'loading-text': isTableLoading.value }])
        }, [
          _createElementVNode("span", _hoisted_6, _toDisplayString(segmentClients.value.toLocaleString()), 1),
          _createTextVNode(" — " + _toDisplayString((100 - +without_orders.value.total_clients_percent).toLocaleString()) + "% ", 1)
        ], 2)
      ])
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["segments-header__toggle", { '_in-develop': isTableLoading.value }]),
      style: `width: 301px`
    }, [
      _createElementVNode("label", {
        class: _normalizeClass(["segments-header__label", predictedIncomeShow.value === 'off' ? 'fw-600' : '']),
        for: "predictedIncomeShowRadioOn"
      }, [
        _createVNode(inputTypeRadio, {
          modelValue: _unref(store).state.segments.predictedIncomeShow,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(store).state.segments.predictedIncomeShow) = $event)),
          name: "predictedIncomeShowRadio",
          value: "off",
          id: "predictedIncomeShowRadioOn"
        }, null, 8, ["modelValue"]),
        _cache[9] || (_cache[9] = _createElementVNode("span", null, "Средний чек", -1))
      ], 2),
      _createElementVNode("label", {
        class: _normalizeClass(["segments-header__label", predictedIncomeShow.value === 'on' ? 'fw-600' : '']),
        for: "predictedIncomeShowRadioOff"
      }, [
        _createVNode(inputTypeRadio, {
          modelValue: _unref(store).state.segments.predictedIncomeShow,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(store).state.segments.predictedIncomeShow) = $event)),
          name: "predictedIncomeShowRadio",
          value: "on",
          id: "predictedIncomeShowRadioOff"
        }, null, 8, ["modelValue"]),
        _cache[10] || (_cache[10] = _createElementVNode("span", null, "Потенциал выручки", -1))
      ], 2)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["header-legal", { '_in-develop': isTableLoading.value }])
    }, [
      _cache[12] || (_cache[12] = _createElementVNode("div", { class: "header-legal__row" }, [
        _createElementVNode("span", { class: "fw-600" }, "Легальность клиентской базы"),
        _createElementVNode("span", { class: "fw-600" }, "?? %")
      ], -1)),
      _createElementVNode("div", _hoisted_7, [
        _cache[11] || (_cache[11] = _createElementVNode("span", { class: "text-small" }, " Клиент явно дал согласие на отправку информационно - рекламной рассылки ", -1)),
        _createVNode(inputSwitch)
      ])
    ], 2)
  ]))
}
}

})