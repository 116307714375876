<template>
  <div>
    <slot name="elements"></slot>
    <div class="clients-pagination" :style="borderBtns ? '' : 'border:none;'">
      <div class="clients-pagination__info" >
        <span v-if="!hideResultStr">Показаны результаты с {{ firstItemIndex }} по {{ lastItemIndex }} из
        {{ props.totalItems }}</span>
      </div>
      <div class="clients-pagination__btns">
        <!-- Кнопка "Назад" для перехода к предыдущим страницам -->
        <button
          v-if="visibleStartPage > 1"
          @click="showPrevPages"
          class="clients-pagination__btn"
        >
          Назад
        </button>
        <!-- Кнопка для перехода на первую страницу -->
        <button
          v-if="visibleStartPage > 1"
          @click="goToPage(1)"
          :class="{ _active: currentPage === 1 }"
          class="clients-pagination__btn"
        >
          1
        </button>
        <!-- Пробел между кнопками -->
        <span
          v-if="visibleStartPage > 1"
          class="clients-pagination__spacer"
        ></span>

        <!-- Кнопки для видимых страниц -->
        <button
          v-for="page in visiblePages"
          :key="page"
          @click="goToPage(page)"
          :class="{ _active: currentPage === page }"
          class="clients-pagination__btn"
        >
          {{ page }}
        </button>

        <!-- Пробел и кнопка для перехода на последнюю страницу -->
        <span
          v-if="visibleStartPage + 2 < totalPages"
          class="clients-pagination__spacer"
        ></span>
        <button
          v-if="visibleStartPage + 2 < totalPages"
          @click="goToPage(totalPages)"
          :class="{ _active: currentPage === totalPages }"
          class="clients-pagination__btn"
        >
          {{ totalPages }}
        </button>
        <!-- Кнопка "Вперед" для перехода к следующим страницам -->
        <button
          v-if="visibleStartPage + 2 < totalPages"
          @click="showNextPages"
          class="clients-pagination__btn"
        >
          Вперед
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, defineEmits, onMounted } from "vue";

// Определение свойств компонента
const props = defineProps<{
  totalItems: number;
  itemsPerPage: number;
  currentItems: Item[];
  defaultPage?: number; // проп для дефолтной страницы
  borderBtns?: boolean; // убрать border у блока с кнопками
  hideResultStr?: boolean; // скрыть сточку "Показаны результаты ..."
}>();

// Определение событий, которые компонент может испускать
const emit = defineEmits(["getPage"]);

// Текущая страница, инициализируется значением из пропа или 1
const currentPage = ref(props.defaultPage || 1);

// Общее количество страниц
const totalPages = computed(() =>
  Math.ceil(props.totalItems / props.itemsPerPage)
);

// Начальная видимая страница
const visibleStartPage = ref(1);

// Вычисление видимых страниц
const visiblePages = computed(() => {
  const pages = [];
  for (
    let i = visibleStartPage.value;
    i <= Math.min(visibleStartPage.value + 2, totalPages.value);
    i++
  ) {
    pages.push(i);
  }
  return pages;
});

// Индексы первого и последнего элемента на текущей странице
const firstItemIndex = computed(
  () => (currentPage.value - 1) * props.itemsPerPage + 1
);
const lastItemIndex = computed(() =>
  Math.min(currentPage.value * props.itemsPerPage, props.totalItems)
);

// Функция для перехода на указанную страницу
function goToPage(page: number) {
  currentPage.value = page;
  emit("getPage", page);

  visibleStartPage.value = Math.max(1, page - 1);
}

// Функция для показа предыдущих страниц
function showPrevPages() {
  visibleStartPage.value = Math.max(1, visibleStartPage.value - 3);
}

// Функция для показа следующих страниц
function showNextPages() {
  visibleStartPage.value = Math.min(
    totalPages.value - 2,
    visibleStartPage.value + 3
  );
}

// Вызов события getPage при монтировании компонента, если установлена дефолтная страница
onMounted(() => {
  if (props.defaultPage) {
    emit("getPage", currentPage.value);
    visibleStartPage.value = Math.max(1, currentPage.value - 1);
  }
});
</script>

<style lang="scss" scoped>
.clients-pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 50px;
  font-size: 16px;
  border-top: solid 1px rgb(51 51 51 / 20%);
  border-bottom: solid 1px rgb(51 51 51 / 20%);

  &__info {
    font-size: 16px;
    font-weight: 500;
  }

  &__btns {
    display: flex;
    gap: 7px;
    
  }

  &__btn {
    border: solid 1px #bdbdbd;
    height: 34px;
    min-width: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
    transition: 0.1s;
    padding: 0 10px;

    &._active {
      border-color: #fbcd00;
    }

    &:hover:not(._active) {
      background: rgba(0, 0, 0, 0.08);
    }
  }

  &__spacer {
    display: inline-block;
    width: 5px;
  }
}
</style>
