import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "app-header" }
const _hoisted_2 = {
  key: 0,
  class: "segments-table"
}
const _hoisted_3 = {
  key: 1,
  class: "btn _opacity",
  href: "https://b24-z5t4c9.bitrix24site.ru/crm_form_fnes1/",
  target: "_blank"
}

import { useStore } from "vuex";
import segmentsMenu from "./segmentsMenu.vue";

import segmentTableHeader from "@/components/main-page/segments/segmentTableItems/segmentTableHeader.vue";
import awaitingSegments from "@/components/main-page/segments/segmentTableItems/awaitingSegments.vue";
import dynamicSegments from "@/components/main-page/segments/segmentTableItems/dynamicSegments.vue";

import lastUpdateTimer from "@/components/UI/bloks/lastUpdateTimer.vue";
import searchClientByPhone from "./searchClientByPhone.vue";
import { computed, ref } from "vue";
import { errorText } from "@/store/modules/segments/segmentsModuleTypes";
import { AxiosError } from "axios";
import getCurrentUpdateDateStr from "@/assets/getCurrentUpdateDateStr";

import getSegmentByIndex from '@/functions/getSegmentByIndex'


export default /*@__PURE__*/_defineComponent({
  __name: 'segmentsTable',
  setup(__props) {

const store = useStore();

const modifySegments = computed(() => store.state.segments.modifySegments);

const isTableLoading = computed(() => store.state.segments.isTableLoading);

const showSegmentSettings = computed({
  get: () => store.state.segments.showSegmentSettings,
  set: (newValue) => (store.state.segments.showSegmentSettings = newValue),
});


function openSegmentByIndex(index) {
  if (!isTableLoading.value) {
    const modifySegments = store.state.segments.modifySegments;
    
    const segment = getSegmentByIndex(modifySegments, index)

    if (segment) {
      const currentSegment = modifySegments.awaitingSegments.includes(segment)
        ? modifySegments.awaitSegment
        : segment;
      store.commit("segments/setCurrentSegment", {
        current: currentSegment,
        add: segment,
      });
      showSegmentSettings.value = true;
      window.scrollTo(0, 0);
      store.commit("reactivation/resetCreateReactivation");
    } else {
      console.error(`Segment with index ${index} not found.`);
    }
  }
}

function setCurrentSegment(segment, add) {
  if (!isTableLoading.value) {
    store.commit("segments/setCurrentSegment", {
      current: segment,
      add: add,
    });
    showSegmentSettings.value = true;
    window.scrollTo(0, 0);
    store.commit("reactivation/resetCreateReactivation");
  }
}
const awaitSegment = computed(
  () => store.state.segments.modifySegments.awaitSegment
);

function setAwaitSegment(segment) {
  setCurrentSegment(awaitSegment.value, segment);
}

let stateUpdateTimer = computed(() => !!store.state.auth.bearerToken);
let bgUpdateTimer = ref("#fbcd00");

function updateTableFunction() {
  return store
    .dispatch("segments/getSegmentsTable")
    .then(() => {
      bgUpdateTimer.value = "#fbcd00";
      return getCurrentUpdateDateStr();
    })
    .catch((error: AxiosError<{ description: errorText }>) => {
      console.log(error);
      bgUpdateTimer.value = "#FF4141";
      const errorDescr = error?.response?.data?.description;
      if (errorDescr) {
        return errorDescr;
      } else {
        return "Произошла ошибка - обратитесь в поддержку ";
      }
    });
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(lastUpdateTimer, {
          bgTimer: _unref(bgUpdateTimer),
          interval: 60000,
          updateFunction: updateTableFunction,
          isTimerRunning: _unref(stateUpdateTimer),
          defaultStr: 'Загрузка',
          class: "mb-10px"
        }, null, 8, ["bgTimer", "isTimerRunning"]),
        _createVNode(searchClientByPhone, {
          onOpenSegment: _cache[0] || (_cache[0] = (index) => openSegmentByIndex(index))
        })
      ]),
      (_ctx.$store.state.segments.modifySegments)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(segmentTableHeader, {
              onSetCurrentSegment: _cache[1] || (_cache[1] = (segment) => setCurrentSegment(segment, segment))
            }),
            _createVNode(awaitingSegments, {
              onSetAwaitSegment: _cache[2] || (_cache[2] = (segment) => setAwaitSegment(segment))
            }),
            _createVNode(dynamicSegments, {
              onSetCurrentSegment: _cache[3] || (_cache[3] = (segment) => setCurrentSegment(segment, segment))
            })
          ]))
        : _createCommentVNode("", true)
    ], 512), [
      [_vShow, !showSegmentSettings.value]
    ]),
    (showSegmentSettings.value)
      ? (_openBlock(), _createBlock(segmentsMenu, { key: 0 }))
      : _createCommentVNode("", true),
    (!showSegmentSettings.value)
      ? (_openBlock(), _createElementBlock("a", _hoisted_3, "заявка на доработку"))
      : _createCommentVNode("", true)
  ], 64))
}
}

})