import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { ref, onMounted, onUnmounted, watch } from "vue";

interface Props {
  deathDate: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'pay-taimer',
  props: {
    deathDate: {}
  },
  setup(__props: any) {

const props = __props;

const formattedTime = ref<string>("00:00");
const intervalId = ref<number | null>(null);

const updateTimer = () => {
  const now = new Date();
  const deathDate = new Date(props.deathDate);
  const diffInSeconds = Math.floor(
    (deathDate.getTime() - now.getTime()) / 1000
  );

  if (diffInSeconds <= 0) {
    formattedTime.value = "00:00";
    if (intervalId.value !== null) {
      clearInterval(intervalId.value);
    }
    return;
  }

  const minutes = Math.floor(diffInSeconds / 60);
  const seconds = diffInSeconds % 60;
  formattedTime.value = `${String(minutes).padStart(2, "0")}:${String(
    seconds
  ).padStart(2, "0")}`;
};

onMounted(() => {
  updateTimer();
  intervalId.value = setInterval(updateTimer, 1000);
});

onUnmounted(() => {
  if (intervalId.value !== null) {
    clearInterval(intervalId.value);
  }
});

watch(
  () => props.deathDate,
  () => {
    if (intervalId.value !== null) {
      clearInterval(intervalId.value);
    }
    updateTimer();
    intervalId.value = setInterval(updateTimer, 1000);
  }
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("span", null, _toDisplayString(formattedTime.value), 1))
}
}

})