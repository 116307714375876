<template>
  <div class="overlay">
    <div class="confirmation-box">
      <p class="tit-h3 fw-500 mb-10px" v-html="infoMessage"></p>
      <p class="mb-10px">
        Для подтверждения введите
        <span class="fw-500">"{{ confirmationString }}"</span>
      </p>
      <input
        type="text"
        class="default-input mb-20px"
        v-model="userInput"
        @keyup.enter="confirmAction"
        :class="{ 'input-error': showError }"
        placeholder=""
      />
      <div class="confirmation-box__btns">
        <button class="btn" @click="cancel">Отмена</button>
        <button class="btn _gray" @click="confirmAction">Подтвердить</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

const props = defineProps({
  confirmationString: String,
  infoMessage: String,
  onConfirm: Function,
});

const emit = defineEmits(["cancelConfirm"]);

const userInput = ref("");
const showError = ref(false);

function confirmAction() {
  if (
    userInput.value.toLowerCase() === props.confirmationString.toLowerCase()
  ) {
    props.onConfirm();
    userInput.value = ""; // Очистка поля ввода после подтверждения
    showError.value = false; // Скрыть сообщение об ошибке
  } else {
    showError.value = true; // Показать сообщение об ошибке
  }
}

function cancel() {
  emit("cancelConfirm");
  userInput.value = ""; // Очистка поля ввода при отмене
  showError.value = false; // Скрыть сообщение об ошибке
}
</script>

<style lang="scss" scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px;
}

.confirmation-box {
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 600px;

  .tit-h3 {
    line-height: 1.5;
  }

  input {
    width: 100%;
  }

  &__btns {
    display: flex;
    justify-content: space-around;
    gap: 15px;

    button {
      flex-grow: 1;
    }
  }

  .default-input {
    font-size: 16px;
    color: #000;
    padding: 10px;
  }
}

.input-error {
  border-color: #ff4d4f;
  margin-bottom: 20px;
}
</style>
