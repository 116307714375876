import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "select-elements" }
const _hoisted_2 = { class: "select-elements__list" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "select-elements__label" }

import { TypeFilialUserData } from "@/types/typesOfApi";


type props = {
  modelValue: TypeFilialUserData[];
};
/* eslint-disable-next-line */

export default /*@__PURE__*/_defineComponent({
  __name: 'select-elements',
  props: {
    modelValue: {}
  },
  emits: ['update:modelValue'],
  setup(__props: any, { emit: __emit }) {

const emit = __emit;

const props = __props;

function selectedElement(index: number) {
  const currentSelectedValue = !props.modelValue[index].value
  const newModelValue = [...props.modelValue]
  newModelValue[index].value = currentSelectedValue
  emit('update:modelValue', newModelValue)
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "default"),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.modelValue, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "select-elements__item",
          key: index,
          onClick: ($event: any) => (selectedElement(index))
        }, [
          _createElementVNode("span", _hoisted_4, _toDisplayString(item.name), 1),
          _createElementVNode("span", {
            class: _normalizeClass(["select-elements__value", 'select-elements-' + item.value])
          }, null, 2)
        ], 8, _hoisted_3))
      }), 128))
    ])
  ]))
}
}

})