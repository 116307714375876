<template>
  <div class="presentation-step">
    <div
      class="fs-24px fw-600 mb-20px"
      v-if="info.title"
      v-html="info.title"
    ></div>
    <div
      class="text-right font-ital fw-500 mb-60px"
      v-if="info.subtitle"
      v-html="info.subtitle"
    ></div>

    <slot></slot>

    <div
      class="fs-20px mb-50px"
      v-if="info.beforeListsText"
      v-html="info.beforeListsText"
    ></div>

    <div v-if="info.firstList">
      <div
        class="fw-500 font-ital mb-7px"
        v-if="info.firstList.title"
        v-html="info.firstList.title"
      ></div>
      <ul class="default-ul mb-50px">
        <li
          v-for="(item, index) in info.firstList.list"
          :key="index"
          v-html="item"
        ></li>
      </ul>
    </div>

    <div v-if="info.secondList">
      <div class="fw-500 mb-7px font-ital" v-html="info.secondList.title"></div>
      <ol class="default-ol mb-50px">
        <li
          v-for="(item, index) in info.secondList.list"
          :key="index"
          v-html="item"
        ></li>
      </ol>
    </div>

    <div
      class="fw-500 font-ital mb-20px"
      v-if="info.afterListsText"
      v-html="info.afterListsText"
    ></div>

    <img
      v-if="info.img"
      class="mb-20px"
      :src="require(`@/assets/images/` + info.img)"
      alt=""
      :class="{ _alone: isAloneImg }"
    />

    <div
      class="text-right fw-500 font-ital"
      v-if="info.supTextBtn"
      v-html="info.supTextBtn"
    ></div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { useStore } from "vuex";

const store = useStore();

type props = {
  info?: {
    title?: string;
    subtitle?: string;

    firstList?: {
      title: string;
      list: string[];
    };
    secondList?: {
      title: string;
      list: string[];
    };

    img?: string;

    beforeListsText?: string;
    afterListsText?: string;

    supTextBtn?: string;
  };
};

/* eslint-disable-next-line */
const props = defineProps<props>();

const isAloneImg = computed(() => {
  // если нет двух списков, картинка будет на весь экран
  return !props.info.firstList && !props.info.secondList;
});
</script>

<style lang="scss" scoped>
img {
  width: 100%;
}
img._alone {
  width: 100%;
  height: auto;
  position: relative;
  left: -60px;
  width: calc(100% + 185px);
}
</style>
