import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main-page" }

import startSettings from "@/components/main-page/startSettings.vue";
import segmentsTable from "@/components/main-page/segments/segmentsTable.vue";
import { computed } from "vue";
import { useStore } from "vuex";


export default /*@__PURE__*/_defineComponent({
  __name: 'mainPage',
  setup(__props) {

const store = useStore();

const mainPageStep = computed(() => store.state.mainPageStep);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (mainPageStep.value <= 2)
      ? (_openBlock(), _createBlock(startSettings, { key: 0 }))
      : (_openBlock(), _createBlock(segmentsTable, { key: 1 }))
  ]))
}
}

})