import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "confirm-reactivation" }

import { useStore } from "vuex";


export default /*@__PURE__*/_defineComponent({
  __name: 'confirmReactivation',
  setup(__props) {

const store = useStore()

function prevStep() {
  store.state.reactivation.reactivationCreateStep = 1
}

function createReactivation() {
  store.dispatch('reactivation/createReactivation')
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "confirm-reactivation__row" }, " Мы не несем ответственности за возможную блокировку номера или другие санкции, связанные с отправкой сообщений. Пожалуйста, убедитесь, что вы ознакомлены с правилами и рекомендациями для безопасной отправки сообщений через мессенджеры. ", -1)),
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "confirm-reactivation__row" }, " Нажимая на кнопку, вы подтверждаете, что понимаете риски и принимаете их. ", -1)),
    _createElementVNode("div", { class: "confirm-reactivation__btns" }, [
      _createElementVNode("button", {
        class: "btn _gray",
        onClick: prevStep
      }, "Назад"),
      _createElementVNode("button", {
        class: "btn",
        onClick: createReactivation
      }, "Я понимаю риски и хочу продолжить")
    ])
  ]))
}
}

})