import { createStore } from "vuex";
import { IState } from "./types";
import authModule from "./modules/auth/authModule";
import segmentsModule from "./modules/segments/segmentsModule";
import tarifModule from "./modules/tariffs/tariffsModule";
import paymentModule from './modules/payment/paymentModule'
import reactivationModule from "./modules/reactivation/reactivationModule";

export default createStore<IState>({
  state: {
    version: '1.0.3',
    supportLink: "https://wa.me/79399513035",
    mainPageStep: 3,
    salon_id: null,
    mainLoader: false,
    errorBlock: {
      code: null,
      text: null,
      show: false,
    },
  },
  getters: {},
  mutations: {
    setMainPageStep(state: IState, step: number) {
      state.mainPageStep = step;
    },
    setMainLoader(state: IState, value: boolean) {
      state.mainLoader = value
    },
    openErrorBlock(state:IState, {code, text}) {
      state.errorBlock.show = true
      state.errorBlock.code = code
      state.errorBlock.text = text
    },
    closeOpenBlock(state:IState) {
      state.errorBlock.show = false
    },
  },
  actions: {},
  modules: {
    auth: authModule,
    segments: segmentsModule,
    tarif: tarifModule,
    payment: paymentModule,
    reactivation: reactivationModule,
  },
});
