<template>
  <div
    class="clients-pagination-list"
    :class="clientPageLoading ? '_loading' : ''"
  >
    <paginationBlock
      @getPage="(pageNumber) => getPage(pageNumber)"
      :totalItems="total_clients"
      :itemsPerPage="25"
      :defaultPage="defaultPaginationPage"
    >
      <template v-slot:elements>
        <zebraTable
          :headerElements="tableHeader"
          :elements="clientsList"
          :elementsOrder="['phone', 'name', 'day_count', 'visit_count']"
          :gridColumns="'3fr 3fr 3fr 4fr'"
          :idFieldName="'client_id'"
        ></zebraTable>
      </template>
    </paginationBlock>
  </div>
</template>

<script lang="ts" setup>
import { useStore } from "vuex";
import zebraTable from "@/components/UI/bloks/zebraTable.vue";
import paginationBlock from "@/components/UI/bloks/paginationBlock.vue";
import { computed, ref, onMounted, watch, onUnmounted } from "vue";

const tableHeader = ["Номер", "Имя", "Давность", "Визитов"];
const store = useStore();

const currentClientPage = computed(
  () => store.state.segments.clients.currentClientPage
);

const clientPageLoading = computed(
  () => store.state.segments.clients.clientPageLoading
);

const defaultPaginationPage = computed({
  get: () => store.state.segments.clients.defaultRecomendationPageClients,
  set: (newVal) =>
    (store.state.segments.clients.defaultRecomendationPageClients = newVal),
});

const total_clients = computed(() => +currentClientPage.value.total);

const clientsList = computed(() => currentClientPage.value.segment_clients);

const currentPage = ref(1);

function getPage(pageNumber) {
  currentPage.value = pageNumber;
  const elements = Array.from(document.querySelectorAll('[id^="client_id_"]'));
  if (elements) {
    elements.forEach((i) => (i.style.outline = ""));
  }

  store
    .dispatch("segments/clients/getClientsForSegmentByPage", {
      segmentIndex: currentIndex.value,
      page: pageNumber,
    })
    .then(() => {
      setTimeout(() => {
        const id = store.state.segments.clients.scrollToClientId;
        const elements = Array.from(
          document.querySelectorAll('[id^="client_id_"]')
        );
        elements.forEach((i) => (i.style.outline = ""));
        if (id) {
          const element = document.querySelector(`#${id}`);
          element.scrollIntoView({ behavior: "smooth", block: "center" });
          element.style.outline = "blue 1px solid";
          store.state.segments.clients.scrollToClientId = "";
          store.state.segments.clients.defaultPaginationPage = 1;
        }
      }, 200);
    });
}

const currentSegment = computed(
  () => store.state.segments.currentSelectSegment
);

const currentIndex = computed(() =>
  currentSegment.value.key === "awaiting_segments"
    ? 121314
    : currentSegment.value.index
);

onUnmounted(() => {
  defaultPaginationPage.value = 1;
});
</script>

<style lang="scss" scoped>
.clients-pagination-list {
  &._loading {
    filter: blur(5px);
  }
}
</style>
