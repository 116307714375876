import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id", "checked"]
const _hoisted_2 = { class: "label-text" }

import { ref, watch } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'input-check',
  props: {
    modelValue: { type: Boolean },
    label: {},
    id: {}
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const isChecked = ref(props.modelValue);

watch(
  () => props.modelValue,
  (newValue) => {
    isChecked.value = newValue;
  }
);

const handleChange = (event: Event) => {
  const target = event.target as HTMLInputElement;
  emit("update:modelValue", target.checked);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("label", {
    class: _normalizeClass([{ checked: isChecked.value }, "custom-checkbox"])
  }, [
    _createElementVNode("input", {
      type: "checkbox",
      id: _ctx.id,
      checked: isChecked.value,
      onChange: handleChange
    }, null, 40, _hoisted_1),
    _cache[0] || (_cache[0] = _createElementVNode("span", { class: "checkmark" }, null, -1)),
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.label), 1)
  ], 2))
}
}

})