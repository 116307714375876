import months from "@/assets/months";
export default function getCurrentUpdateDateStr() {
  const now = new Date();

  const day = now.getDate();
  const month = months[now.getMonth()].short;
  const year = now.getFullYear();
  const hours = now.getHours().toString().padStart(2, "0");
  const minutes = now.getMinutes().toString().padStart(2, "0");
  const dateTimeString = `${day} ${month} ${year} - ${hours}:${minutes}`;
  return `Последнее обновление <span class="fw-600">${dateTimeString}</span>`;
}
