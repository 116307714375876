import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "segments-menu"
}
const _hoisted_2 = { class: "segments-menu__inner" }

import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import segmentSettings from './segmentSettings'


export default /*@__PURE__*/_defineComponent({
  __name: 'segmentsMenu',
  setup(__props) {

const store = useStore();

const modifySegments = computed(() => store.state.segments.modifySegments);

const allSegmentsArr = computed(() => {
  return [...modifySegments.value.dynamicSegments, modifySegments.value.didNotCome, modifySegments.value.outflow].sort((a, b) => a.index - b.index)
})

const activeSegment = computed(() => store.state.segments.currentSettingSegment)


return (_ctx: any,_cache: any) => {
  return (modifySegments.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (activeSegment.value)
            ? (_openBlock(), _createBlock(_unref(segmentSettings), { key: 0 }))
            : _createCommentVNode("", true)
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})