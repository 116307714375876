<template>
    <button @click="toggleEmojiPicker"><slot></slot></button>
</template>

<script setup lang="ts">
import { EmojiButton } from "@joeattardi/emoji-button";
import { onMounted, ref, defineEmits } from "vue";

// Объявляем событие emit
const emit = defineEmits(["emoji-selected"]);

const inputValue = ref("");
const picker = ref(null);

onMounted(() => {
  picker.value = new EmojiButton();
  picker.value.on("emoji", (emoji) => {
    // Вызываем событие emit при выборе смайлика
    console.log(emoji.emoji)
    emit("emojiSelected", emoji);
  });
});

function toggleEmojiPicker(event) {
  picker.value.togglePicker(event.currentTarget);
}
</script>

<style scoped lang="scss"></style>
