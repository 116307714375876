import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "settings-menu"
}
const _hoisted_2 = {
  key: 1,
  class: "segments-nav"
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "segment-settings__container" }

import { useStore } from "vuex";
import { TypeSegment } from "@/store/modules/segments/segmentsModuleTypes";
import { computed, ref } from "vue";

import segmentRecomendation from "@/components/main-page/segments/segmentRecomendation.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'segmentSettings',
  setup(__props) {

const currentSettingsSegment = computed(
  () => store.state.segments.currentSettingSegment
);

const currentSelectSegment = computed(
  () => store.state.segments.currentSelectSegment
);

const currentShowBlockIndex = ref(0);

const store = useStore();

const awaitingSegments = computed(
  () => store.state.segments.modifySegments.awaitingSegments
);
const awaitSegment = computed(
  () => store.state.segments.modifySegments.awaitSegment
);

const segmentAwaitingItems = [
  {
    name: "Все",
    key: "awaiting_segments",
  },
  {
    name: "Новички",
    key: "expect_newcomer",
  },
  {
    name: "Растущие",
    key: "expect_growing",
  },
  {
    name: "Чемпионы",
    key: "expect_champion",
  },
];

function setAwaitSetting(key) {
  if (key === "awaiting_segments") {
    store.state.segments.currentSelectSegment = awaitSegment.value;
  } else {
    store.state.segments.currentSelectSegment = awaitingSegments.value.find(
      (i) => i.key === key
    );
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["segment-settings", currentSettingsSegment.value.key === 'awaiting_segments' ? '_await' : ''])
  }, [
    false
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("button", {
            class: _normalizeClass(["settings-menu__item menu-link", currentShowBlockIndex.value === 0 ? `active` : '']),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (currentShowBlockIndex.value = 0))
          }, " Рекомендации по сегменту ", 2),
          _createElementVNode("button", {
            class: _normalizeClass(["settings-menu__item menu-link", currentShowBlockIndex.value === 1 ? `active` : '']),
            onClick: _cache[1] || (_cache[1] = ($event: any) => (currentShowBlockIndex.value = 1))
          }, " Клиенты сегмента ", 2)
        ]))
      : _createCommentVNode("", true),
    (currentSettingsSegment.value.key === 'awaiting_segments' && false)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(segmentAwaitingItems, (item) => {
            return _createElementVNode("button", {
              key: item.key,
              onClick: ($event: any) => (setAwaitSetting(item.key)),
              class: _normalizeClass(["segments-nav__item menu-link", 
          currentSelectSegment.value && currentSelectSegment.value.key === item.key
            ? 'active'
            : ''
        ])
            }, _toDisplayString(item.name), 11, _hoisted_3)
          }), 64))
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      (currentSelectSegment.value && currentShowBlockIndex.value === 0)
        ? (_openBlock(), _createBlock(segmentRecomendation, {
            key: 0,
            segment: currentSelectSegment.value
          }, null, 8, ["segment"]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}
}

})