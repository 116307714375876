<template>
  <div class="container" :class="{ _loader: mainLoaderState }">
    <segHeader></segHeader>
    <router-view></router-view>
  </div>
  <div class="main-loader" v-show="mainLoaderState"></div>

  <div class="error-block" v-if="store.state.errorBlock.show">
    <div class="tit-h1">Ошибка {{ store.state.errorBlock.code }}</div>
    <p class="text" v-if="!store.state.errorBlock.text">
      <span class="fw-600">Уважаемый клиент!</span> <br />
      <br />

      На данный момент у Вас уже подключено наше приложение:
      <span class="fw-600">«{{ anotherAppName }}»</span>
      <br />
      <br />

      Для подключения нового приложения необходимо сначала отключить уже
      установленное приложение. <br />
      <br />

      Это связано с техническими ограничениями: одновременно может быть
      подключено только одно из приложений, иначе работа системы будет нарушена.
      <br /><br />

      Мы рекомендуем продолжить использование уже подключенного приложения, так
      как оно функционирует в полном объеме. <br />
      <br />

      Если Вам всё же необходимо сменить приложение, пожалуйста, сначала
      отключите текущее через настройки интеграций, а затем повторите попытку
      подключения.

      <br /><br />
      Если у Вас возникли вопросы или нужна помощь, наша поддержка готова помочь
      Вам в любое время. С уважением, Команда поддержки «Сегменты»
    </p>
    <a :href="linkInAnotherApp" class="blue-link" target="_top"
      >Перейти к подключенному приложению</a
    >
    <br />
    <br />
    <a href="https://t.me/Segment_support_bot" target="_top" class="blue-link">
      Написать в поддержку</a
    >
  </div>

  <div class="main-pres-block" v-if="isShowPresentation">
    <segPresentation></segPresentation>
  </div>
</template>

<script setup lang="ts">
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import segHeader from "@/components/segHeader.vue";
import { onMounted, computed, watch } from "vue";
import exampleTableResponse from "@/assets/exampleTableResponse";

import segPresentation from "@/components/presentation/segments-presentation.vue";

const store = useStore();

const route = useRoute();
const router = useRouter();

const additionalInfo = computed(() => store.state.auth.additionalInfo);
const isShowPresentation = computed(
  () => additionalInfo.value.is_showed_presentation === 0
);

const mainLoaderState = computed(() => store.state.mainLoader);
const notitficationBlock = computed(() => store.state.notitficationBlock);
const currentNotificationStyles = computed(() => {
  const styles = notitficationBlock.value.styles[notitficationBlock.value.type];
  if (notitficationBlock.value.text) {
    return `background-color:${styles.bg}; color: ${styles.color}; opacity: 1;`;
  } else {
    return "";
  }
});

function parseQueryString(queryString) {
  // Удаляем знак вопроса в начале строки, если он есть
  if (queryString.startsWith("?")) {
    queryString = queryString.slice(1);
  }

  // Разбиваем строку на пары ключ-значение
  const pairs = queryString.split("&");
  const result = {};

  pairs.forEach((pair) => {
    const [key, value] = pair.split("=");
    // Декодируем ключ и значение и добавляем их в объект
    result[decodeURIComponent(key)] = decodeURIComponent(value);
  });

  return result;
}

const appParams = computed(() => parseQueryString(store.state.auth.authParams));

let appsId = {
  segmentsPlusWa: 8316,
  segments: 12308,
};

const linkInAnotherApp = computed(() => {
  const appId = appParams.value.application_id === 8316 ? 12308 : 8316;
  return `https://yclients.com/appstore/${appParams.value.salon_id}/applications/${appId}/info`;
});

const anotherAppName = computed(() => {
  return appParams.value.application_id === 8316
    ? "Сегменты + WhatsApp"
    : "Сегменты";
});

onMounted(() => {
  store.commit(
    "segments/modifyAndSetSegments",
    exampleTableResponse.company_rfm
  );
  // store.commit("setMainLoader", true);
  const params = {};
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  console.log(queryString, urlParams);
  urlParams.forEach((value, key) => {
    params[key] = value;
  });

  if (urlParams.get("salon_id")) {
    store.state.salon_id = +params.salon_id;
    store.state.auth.isShowWhatsApp = +params.application_id === 8316;

    store.dispatch("auth/registraion").then(() => {
      Promise.all([
        store.dispatch("tarif/getAllTariffs"),
        store.dispatch("tarif/getCurrentTarif"),
      ]).finally(() => {
        store.commit("setMainLoader", false);
        if (!store.state.tarif?.currentTarif?.active) {
          router.push("/tariffs");
        } else {
          store.dispatch("segments/getSegmentsTable");
        }
      });
    });
  }

  window.addEventListener("message", (event) => {
    if (event.data === "scrollUp") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  });
});
</script>

<style src="./assets/fonts/stylesheet.css"></style>
<style src="./assets/reset.css"></style>
<style lang="scss">
@import "@/assets/variables.scss";

.main-loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  background: url(~@/assets/images/main-loader.svg) no-repeat center / cover;
  filter: drop-shadow(0px 0px 15px #cfcfcf);
}
.main-pres-block {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(80, 115, 145, 0.8);
  z-index: 4;
  padding: 100px 40px;
  overscroll-behavior: contain;
  overflow: auto;
}
.--loading {
  filter: blur(5px) !important;
  pointer-events: none !important;
}
.error-block {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;

  border-radius: 5px;

  .tit-h1 {
    font-size: 30px;
    font-weight: bold;
    color: #721c24;
    margin-bottom: 10px;
  }

  .text {
    font-size: 16px;
    color: #333;
    margin-bottom: 15px;
  }
}

.blue-link {
  font-size: 16px;
  color: #0056b3;
  text-decoration: none;
  border-bottom: 1px dashed #0056b3;
  transition: color 0.3s, border-bottom-color 0.3s;

  &:hover {
    color: #003d80;
    border-bottom-color: #003d80;
  }
}

.small-loader {
  width: 20px;
  height: 20px;
  background: url(~@/assets/images/small-loader.svg) no-repeat center / cover;
  filter: drop-shadow(0px 0px 15px #cfcfcf);
}

.loading-text {
  overflow: hidden;
  width: 100px;
  height: 18px;
  will-change: background-position;
  background: linear-gradient(90deg, #f0f0f0, #9a9b9b, #f0f0f0);
  background-size: 300% 100%; /* Увеличен размер фона */
  animation: shimmer 3s infinite linear; /* Уменьшено время анимации и добавлен linear для равномерности */
  color: transparent;
  opacity: 0.6;
  border-radius: 7px;
}
.loading-block {
  overflow: hidden;
  width: 100%;
  height: 100%;
  will-change: background-position;
  background: linear-gradient(90deg, #f0f0f0, #9a9b9b, #f0f0f0);
  background-size: 300% 100%; /* Увеличен размер фона */
  animation: shimmer 3s infinite linear; /* Уменьшено время анимации и добавлен linear для равномерности */
  color: transparent;
  opacity: 0.6;
  pointer-events: none;
}

@keyframes shimmer {
  0% {
    background-position: 200% 0; /* Изменено начальное положение */
  }
  100% {
    background-position: -100% 0;
  }
}

.container {
  max-width: 1610px;
  position: relative;

  @media (max-width: 1500px) {
    width: 1500px;
    overflow: auto;
  }

  &._loader {
    filter: blur(10px);
    pointer-events: none;
  }
}

body {
  font-family: Graphik LCG;
}
.tit-h1 {
  font-size: 32px;
}
.tit-h2 {
  font-size: 26px;
}
.tit-h3 {
  font-size: 20px;

  &.bold {
    font-weight: 500;
  }
}
.subtitle {
  font-size: 16px;
  font-weight: 400;
}
.btn {
  background: $yellowBtnBg;
  padding: 16px 25px;
  border-radius: 12px;
  text-align: center;
  transition: 0.1s;

  &:not([disabled]) {
    cursor: pointer;
  }

  &._border-raduis-9px {
    border-radius: 9px;
  }

  &._opacity {
    background: #ffe4aa;
    font-weight: 400;
  }

  &._gray {
    background: #f7f9fc;
  }
  &._blue {
    background: $blueBtnBg;
  }
  &._green {
    background: $greenBtnGb;
  }
  &._green1 {
    background: #5aa78b;
    color: #fff;

    &:hover:not([disabled]) {
      background: #a3dcc7;
    }
  }
  &._red {
    background: $redBtnBg;
  }

  &._small {
    padding: 10px 12px;
    border-radius: 7px;
  }

  &:hover:not([disabled]) {
    background: #fffaed;
  }

  &:disabled {
    cursor: default;
    background: #fffaed;
    color: #000;
  }
}

.btn-type1 {
  background-color: #f0f0f0;
  border: none;
  border-radius: 6px;
  padding: 5px 10px;
  padding-top: 7px;
  cursor: pointer;
  font-size: 12px;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;

  &:hover {
    background-color: #e0e0e0;
  }
}

.text-yellow {
  color: $yellowBtnBg;
}
.link {
  cursor: pointer;
  color: $yellowBtnBg;
  text-decoration: none;
  font-weight: 500;
}

.fw-600 {
  font-weight: 600 !important;
}
.fw-500 {
  font-weight: 500 !important;
}
.fs-24px {
  font-size: 24px !important;
}
.fs-20px {
  font-size: 20px !important;
}
.fs-18px {
  font-size: 18px !important;
}
.fs-16px {
  font-size: 16px !important;
}
.fs-12px {
  font-size: 12px !important;
}
.fs-10px {
  font-size: 10px !important;
}

.mb-60px {
  margin-bottom: 60px !important;
}
.mb-50px {
  margin-bottom: 50px !important;
}
.mb-15px {
  margin-bottom: 15px !important;
}
.mb-13px {
  margin-bottom: 13px !important;
}
.mb-10px {
  margin-bottom: 10px !important;
}
.mb-7px {
  margin-bottom: 7px !important;
}

.default-ul {
  list-style-type: disc;
  padding-left: 19px;

  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 16px;
  line-height: 120%;
}
.default-ol {
  padding-left: 18px;
  list-style-type: decimal;
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 16px;
  line-height: 120%;
}

.font-ital {
  font-style: italic !important;
}

.text-right {
  text-align: right !important;
}

.default-input {
  border: solid 1px #bdbdbd;
  border-radius: 6px;
  font-size: 11px;
  padding: 5px 16px;
  color: #71757f;
  min-height: 32px;

  &._error {
    border-color: #d73333;
  }
}
.text-error {
  color: #d73333 !important;
  font-weight: 500;
  opacity: 1;
}
.text-small {
  color: #71757f;
  font-size: 13px;
}
.fw-900 {
  font-weight: 900 !important;
}
.fw-500 {
  font-weight: 500;
}
._in-develop {
  pointer-events: none;
  opacity: 0.3;
}

.font-ital {
  font-style: italic;
}

.mb-20px {
  margin-bottom: 20px;
}
.mt-11px {
  margin-top: 11px;
}

.i-success {
  width: 35px;
  height: 35px;
  background: url(~@/assets/images/success-icon.svg) no-repeat center / 100%;
}
.i-failed {
  width: 35px;
  height: 35px;
  background: url(~@/assets/images/failed-icon.svg) no-repeat center / 100%;
}

.i-confetti {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url(~@/assets/images/emoji-confetti.webp) no-repeat center / 100%;
}
</style>
