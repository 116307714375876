<template>
  <div class="segments-header">
    <div class="segments-header__border">
      <div
        class="segments-header__info _all base-segment _border-radius"
        :style="`background-color: #fff; border: solid 2px ${without_orders.border_color}`"
      >
        <div>Всего клиентов в базе</div>
        <div
          class="base-segment__price"
          :class="{ 'loading-text': isTableLoading }"
        >
          <span class="fw-600">{{ allClients.toLocaleString() }}</span>
          — 100%
        </div>
      </div>
      <div class="segments-header__gap">—</div>
      <div
        @click="emit('setCurrentSegment', without_orders)"
        :class="isTableLoading ? '' : '_hover _hoverBorderPlus _border-radius'"
        class="segments-header__info _all base-segment"
        :style="`background-color: #D9D9D9; border: solid 2px #CCCCCC; min-width: 256px`"
      >
        <!-- width:${segment6Width+.9}px -->
        <div>Клиенты без визитов</div>

        <div
          class="base-segment__price"
          :class="{ 'loading-text': isTableLoading }"
        >
          <span class="fw-600">{{
            (+without_orders.total_clients).toLocaleString()
          }}</span>
          — {{ (+without_orders.total_clients_percent).toLocaleString() }}%
        </div>
      </div>
      <div class="segments-header__gap"><span>—</span><span>—</span></div>

      <div
        class="segments-header__info _all base-segment"
        :style="`background-color: #fff; border: solid 2px #ABE9DC; min-width: 270px`"
      >
        <!-- width:${segment6Width+.9}px -->
        <div>Сегментировано клиентов</div>
        <div
          class="base-segment__price"
          :class="{ 'loading-text': isTableLoading }"
        >
          <span class="fw-600">{{ segmentClients.toLocaleString() }}</span>
          —
          {{ (100 - +without_orders.total_clients_percent).toLocaleString() }}%
        </div>
      </div>
    </div>

    <div
      class="segments-header__toggle"
      :class="{ '_in-develop': isTableLoading }"
      :style="`width: 301px`"
    >
      <label
        class="segments-header__label"
        :class="predictedIncomeShow === 'off' ? 'fw-600' : ''"
        for="predictedIncomeShowRadioOn"
      >
        <inputTypeRadio
          v-model="store.state.segments.predictedIncomeShow"
          name="predictedIncomeShowRadio"
          value="off"
          id="predictedIncomeShowRadioOn"
        ></inputTypeRadio>
        <span>Средний чек</span>
      </label>
      <label
        class="segments-header__label"
        :class="predictedIncomeShow === 'on' ? 'fw-600' : ''"
        for="predictedIncomeShowRadioOff"
      >
        <inputTypeRadio
          v-model="store.state.segments.predictedIncomeShow"
          name="predictedIncomeShowRadio"
          value="on"
          id="predictedIncomeShowRadioOff"
        ></inputTypeRadio>
        <span>Потенциал выручки</span>
      </label>
    </div>
    <div class="header-legal" :class="{ '_in-develop': isTableLoading }">
      <div class="header-legal__row">
        <span class="fw-600">Легальность клиентской базы</span>
        <span class="fw-600">?? %</span>
      </div>
      <div class="header-legal__row">
        <span class="text-small">
          Клиент явно дал согласие на отправку информационно - рекламной
          рассылки
        </span>
        <inputSwitch></inputSwitch>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useStore } from "vuex";
import inputSwitch from "@/components/UI/inputs/input-switch.vue";
import inputTypeRadio from "@/components/UI/inputs/input-type-radio.vue";
import { computed, defineEmits } from "vue";

const store = useStore();

const emit = defineEmits(["setCurrentSegment"]);

const company_rfm = computed(() => store.state.segments.company_rfm);
const modifySegments = computed(() => store.state.segments.modifySegments);

const isTableLoading = computed(() => store.state.segments.isTableLoading);

const allClients = computed(() => +company_rfm.value.total_clients);

const without_orders = computed(
  () => store.state.segments.modifySegments.without_orders
);

const segmentClients = computed(() => +company_rfm.value.segment_clients);

const predictedIncomeShow = computed(
  () => store.state.segments.predictedIncomeShow
);
</script>

<style lang="scss" scoped>
.segments-header {
  grid-row: span 1;
  grid-column: span 2;
  display: flex;
  gap: 10.9px;
  align-items: center;

  &__gap {
    width: 38px;
    display: flex;
    flex-direction: column;
    line-height: 6px;
    justify-content: center;
    align-items: center;
    font-weight: 500;
  }

  &__toggle {
    padding-left: 28px;
    border-radius: 12px;
    border: 1px solid #ccc;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
  }
  &__label {
    display: flex;
    font-size: 16px;
    align-items: center;
    gap: 11px;
    cursor: pointer;
  }
  .header-legal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 20px;
    border-radius: 12px;
    margin-right: 4px;

    border: 1px solid #dd6068;
    height: 100%;
    gap: 12px;
    flex-grow: 1;

    &__row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 30px;

      .fw-600:last-child {
        font-size: 20px;
      }
    }
    .text-small {
      font-weight: 500;
      font-size: 10px;
    }
  }

  &__border {
    border: solid 1px #cccccc;
    display: flex;
    border-radius: 15px;

    padding: 5px 4.6px 5px 5px;

    .base-segment {
      &:first-child {
        width: 255px;
      }
      &:nth-child(2) {
      }
    }
  }
  &__info {
    border-radius: 12px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-row-gap: 16px;
    max-width: 506px;
    height: 91px;

    &._segmenting {
      width: 517px;
    }

    &._all {
      background: #fed47a;
      grid-template-columns: 1fr;
    }
  }
}
</style>
