<template>
  <div class="switcher" @click="toggle" :class="isOn ? '_active' : ''">
    <div :class="['switcher-button', { 'switcher-button-on': isOn }]"></div>
  </div>
</template>

<script setup>
import { ref, watch, defineEmits } from "vue";

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["update:modelValue"]);

const isOn = ref(props.modelValue);

watch(
  () => props.modelValue,
  (newValue) => {
    isOn.value = newValue;
  }
);

const toggle = () => {
  isOn.value = !isOn.value;
  emit("update:modelValue", isOn.value);
};
</script>

<style lang="scss" scoped>
.switcher {
  min-width: 44px;
  height: 26px;
  background-color: #ccc;
  border-radius: 51px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.1s;

  box-shadow: inset 0 0px 1px 0 rgba(0, 0, 0, 0.07);
background: #edf2fb;

  &._active {
    background: #ffc549;
  }
}

.switcher-button {
  width: 18px;
  height: 18px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 4px;
  left: 4px;
  transition: left 0.2s;

  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
}

.switcher-button-on {
  left: 22px;
}
</style>
