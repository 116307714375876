<template>
  <div class="template-form">
    <div class="text-small">Введите текст сообщения</div>
    <div class="template-form__redactor">
      <div class="template-form__redaction" :key="channel">
        <div
          class="template-form__text"
          v-html="formattedText"
          style="white-space: pre-wrap"
        ></div>
        <textarea
          ref="textareaRef"
          v-model="textareaValue"
          @input="sanitizeInput"
        ></textarea>
      </div>
      <transition name="fade">
        <div class="template-form__variables" v-if="variablesVisible">
          <button
            class="template-form__variable"
            v-for="variable in variables"
            :key="variable.id"
            :style="`background-color:${variable.color} `"
            @click="addVariable(variable.template)"
          >
            {{ variable.name }}
          </button>
        </div>
      </transition>
    </div>
    <div class="template-form__footer">
      <button class="template-form__btn _in-develop">
        <input type="file" />
        Прикрепить файл
      </button>
      <button
        class="template-form__btn"
        @click="variablesVisible = !variablesVisible"
      >
        Переменные
      </button>
      <emojiButton class="template-form__btn _emoji" @emojiSelected="addEmoji"
        ><img src="~@/assets/images/emoji-open.svg" alt=""
      /></emojiButton>
      <button class="template-form__btn" @click="clearEditor">Очистить</button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useStore } from "vuex";
import inputCheck from "@/components/UI/inputs/input-check.vue";
import inputTypeRadio from "@/components/UI/inputs/input-type-radio.vue";
import emojiButton from "@/components/reactivation/emojiButton.vue";
import { computed, onMounted, ref, watch } from "vue";

const store = useStore();

const activeSegment = computed(() => store.state.segments.currentSelectSegment);

const channels = ref(["Push", "WhatsApp"]);

let variablesVisible = ref(false);

const variables = [
  {
    template: "CLIENT_NAME",
    color: "rgb(92, 195, 198)",
    name: "Имя клиента",
    id: 1,
  },
];

let activeChannel = ref("Push");

const textareaRef = ref(null);

const textareaValue = computed({
  get() {
    return store.state.reactivation.reactivationTemplate;
  },
  set(value) {
    store.commit("reactivation/updateReactivationTemplate", value);
  },
});

const formattedText = computed(() => {
  let text = textareaValue.value;
  variables.forEach((variable) => {
    const regex = new RegExp(`%${variable.template}%`, "gi");
    text = text.replace(
      regex,
      `<span class="var" style="color: ${
        variable.color
      };">%${variable.template.toUpperCase()}%</span>`
    );
  });
  return text;
});

watch(textareaValue, (newValue) => {
  variables.forEach((variable) => {
    const regex = new RegExp(`%${variable.template}%`, "gi");
    textareaValue.value = textareaValue.value.replace(
      regex,
      `%${variable.template.toUpperCase()}%`
    );
  });
});

function sanitizeInput() {
  textareaValue.value = textareaValue.value.replace(/</g, "");
}

function addVariable(template) {
  const textarea = textareaRef.value;
  if (!textarea) return;

  const cursorPosition = textarea.selectionStart;
  const textBefore = textareaValue.value.substring(0, cursorPosition);
  const textAfter = textareaValue.value.substring(cursorPosition);
  textareaValue.value = textBefore + `%${template.toUpperCase()}%` + textAfter;
  variablesVisible.value = false;
  setTimeout(() => {
    if (textarea) {
      textarea.setSelectionRange(
        cursorPosition + template.length + 2,
        cursorPosition + template.length + 2
      );
      textarea.focus();
    }
  }, 0);
}

function addEmoji(emoji) {
  const textarea = textareaRef.value;
  if (!textarea) return;

  const cursorPosition = textarea.selectionStart;
  const textBefore = textareaValue.value.substring(0, cursorPosition);
  const textAfter = textareaValue.value.substring(cursorPosition);
  textareaValue.value = textBefore + emoji.emoji + textAfter;
  setTimeout(() => {
    if (textarea) {
      const newCursorPosition = cursorPosition + emoji.emoji.length;
      textarea.setSelectionRange(newCursorPosition, newCursorPosition);
      textarea.focus();
    }
  }, 0);
}

function clearEditor() {
  textareaValue.value = ""; // Clear the textarea value
}

onMounted(() => {
  const textarea = textareaRef.value;
  if (textarea) {
    textarea.addEventListener("input", function () {
      this.style.height = "auto";
      this.style.height = Math.max(this.scrollHeight, 270) + "px";
    });
  }
});
</script>
<style lang="scss" scoped>
.template-form {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 40px 34px;
  &__channels {
    display: flex;
    gap: 5px;
  }

  &__channel {
    position: relative;
    background: #f0f3f8;
    padding: 16px 24px;
    border-radius: 12px;
    z-index: 1;
    translate: 0 10px;
    font-size: 14px;
    color: #6d6d6d;
    cursor: pointer;

    transition: 0.1s;

    &._active {
      color: #000;
      translate: 0 5px;
      border-radius: 12px 12px 0 0;
      background: #f6f9fc;
    }
  }

  &__redactor {
    position: relative;
  }

  .text-small {
    margin-bottom: 10px;
  }

  &__redaction {
    position: relative;
    z-index: 2;
    background: #fff;
    min-height: 303px;
    font-size: 16px;
    line-height: 187%;
    padding: 20px 30px;
    outline: none;
    border: 1px solid #71757f99;
    border-radius: 12px 12px 0 0;
    overflow: auto;

    textarea {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      outline: none;
      padding: 20px 30px;
      font-size: 16px;
      line-height: 187%;
      border-radius: 12px 12px 0 0;
      min-height: 270px;
      overflow: hidden;

      background-color: transparent; /* Прозрачный фон */
      color: transparent; /* Прозрачный текст */
      caret-color: black; /* Цвет курсора */
      resize: none; /* Отключить изменение размера */
    }
  }

  &__text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    padding: 20px 30px;
    font-size: 16px;
    line-height: 187%;
    border-radius: 12px 12px 0 0;
  }

  &__variables {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
    gap: 10px;
    align-items: start;
    background: #fff;

    padding: 20px 30px;
    border: 1px solid #71757f99;
    border-radius: 12px 12px 0 0;
  }
  &__variable {
    padding: 5px 10px;
    border-radius: 49px;
    color: #fff;
    cursor: pointer;
  }

  &__footer {
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 16px;
    padding: 15px;

    border: 1px solid #71757f99;
    border-top: none;
    border-radius: 0 0 12px 12px;
  }

  &__btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    // box-shadow: 0 1px 2px 0 rgba(21, 21, 21, 0.15);
    border: solid 1px #71757f99;
    background: #fff;
    border-radius: 12px;
    min-height: 44px;
    color: #6d6d6d;
    padding: 0 24px;
    cursor: pointer;
    position: relative;

    input[type="file"] {
      cursor: pointer;
      position: absolute;
      opacity: 0;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &._emoji {
      padding: 0 16px;

      img {
        transform: translate(0.5px, 1px);
        cursor: pointer;
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>

<style lang="scss">
.template-form-variable {
  text-transform: uppercase;
  display: inline-block;
}
</style>
