import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "presentation-step" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { key: 3 }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "default-ul mb-50px" }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { key: 4 }
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = { class: "default-ol mb-50px" }
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = ["src"]
const _hoisted_15 = ["innerHTML"]

import { computed } from "vue";
import { useStore } from "vuex";

type props = {
  info?: {
    title?: string;
    subtitle?: string;

    firstList?: {
      title: string;
      list: string[];
    };
    secondList?: {
      title: string;
      list: string[];
    };

    img?: string;

    beforeListsText?: string;
    afterListsText?: string;

    supTextBtn?: string;
  };
};

/* eslint-disable-next-line */

export default /*@__PURE__*/_defineComponent({
  __name: 'presentation-step',
  props: {
    info: {}
  },
  setup(__props: any) {

const store = useStore();

const props = __props;

const isAloneImg = computed(() => {
  // если нет двух списков, картинка будет на весь экран
  return !props.info.firstList && !props.info.secondList;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.info.title)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "fs-24px fw-600 mb-20px",
          innerHTML: _ctx.info.title
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true),
    (_ctx.info.subtitle)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "text-right font-ital fw-500 mb-60px",
          innerHTML: _ctx.info.subtitle
        }, null, 8, _hoisted_3))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default"),
    (_ctx.info.beforeListsText)
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: "fs-20px mb-50px",
          innerHTML: _ctx.info.beforeListsText
        }, null, 8, _hoisted_4))
      : _createCommentVNode("", true),
    (_ctx.info.firstList)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          (_ctx.info.firstList.title)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "fw-500 font-ital mb-7px",
                innerHTML: _ctx.info.firstList.title
              }, null, 8, _hoisted_6))
            : _createCommentVNode("", true),
          _createElementVNode("ul", _hoisted_7, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.info.firstList.list, (item, index) => {
              return (_openBlock(), _createElementBlock("li", {
                key: index,
                innerHTML: item
              }, null, 8, _hoisted_8))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.info.secondList)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createElementVNode("div", {
            class: "fw-500 mb-7px font-ital",
            innerHTML: _ctx.info.secondList.title
          }, null, 8, _hoisted_10),
          _createElementVNode("ol", _hoisted_11, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.info.secondList.list, (item, index) => {
              return (_openBlock(), _createElementBlock("li", {
                key: index,
                innerHTML: item
              }, null, 8, _hoisted_12))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.info.afterListsText)
      ? (_openBlock(), _createElementBlock("div", {
          key: 5,
          class: "fw-500 font-ital mb-20px",
          innerHTML: _ctx.info.afterListsText
        }, null, 8, _hoisted_13))
      : _createCommentVNode("", true),
    (_ctx.info.img)
      ? (_openBlock(), _createElementBlock("img", {
          key: 6,
          class: _normalizeClass(["mb-20px", { _alone: isAloneImg.value }]),
          src: require(`@/assets/images/` + _ctx.info.img),
          alt: ""
        }, null, 10, _hoisted_14))
      : _createCommentVNode("", true),
    (_ctx.info.supTextBtn)
      ? (_openBlock(), _createElementBlock("div", {
          key: 7,
          class: "text-right fw-500 font-ital",
          innerHTML: _ctx.info.supTextBtn
        }, null, 8, _hoisted_15))
      : _createCommentVNode("", true)
  ]))
}
}

})