import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, isRef as _isRef, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "radio-list" }

import inputTypeRadio from '@/components/UI/inputs/input-type-radio.vue'
import { ref } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'stepOfferService',
  setup(__props) {

let radioValue = ref<'0' | '1'>('0')

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[4] || (_cache[4] = _createElementVNode("div", { class: "subtitle" }, [
      _createTextVNode(" Желаете узнать об услуге - "),
      _createElementVNode("span", { class: "text-yellow" }, "Мой Маркетолог"),
      _createTextVNode(" подробнее? ")
    ], -1)),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(inputTypeRadio, {
        id: "radioTrue",
        name: "radio",
        modelValue: _unref(radioValue),
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(radioValue) ? (radioValue).value = $event : radioValue = $event)),
        value: "0"
      }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createElementVNode("span", { class: "radio-list__label" }, "Да - свяжитесь со мной", -1)
        ])),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(inputTypeRadio, {
        id: "radioFalse",
        name: "radio",
        modelValue: _unref(radioValue),
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(radioValue) ? (radioValue).value = $event : radioValue = $event)),
        value: "1"
      }, {
        default: _withCtx(() => _cache[3] || (_cache[3] = [
          _createElementVNode("span", { class: "radio-list__label" }, "Нет - буду работать самостоятельно", -1)
        ])),
        _: 1
      }, 8, ["modelValue"])
    ])
  ], 64))
}
}

})