import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "radio-group" }
const _hoisted_2 = ["id", "value", "name", "checked"]
const _hoisted_3 = ["for"]


type TypeProps = {
  id: string;
  modelValue: string;
  name: string;
  value: string;
};
/* eslint-disable-next-line */

export default /*@__PURE__*/_defineComponent({
  __name: 'input-type-radio',
  props: {
    id: {},
    modelValue: {},
    name: {},
    value: {}
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

function updateValue(event: any) {
  console.log(event);
  emit("update:modelValue", event.target.value);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      class: "radio-input",
      type: "radio",
      id: props.id,
      value: props.value,
      name: props.name,
      onChange: updateValue,
      checked: props.modelValue === props.value
    }, null, 40, _hoisted_2),
    _createElementVNode("label", {
      for: _ctx.id,
      class: "radio-label"
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 8, _hoisted_3)
  ]))
}
}

})