import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "whatsapp-page" }

import { useStore } from 'vuex';
import whatsAppBlock from '@/components/whatsapp/whatsappBlock.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'whatsAppPage',
  setup(__props) {

const store = useStore();


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "whatsapp-phone" }, [
      _createElementVNode("div", { class: "tit-h4 fw-500" }, [
        _createTextVNode("Если соединение будет разорвано, "),
        _createElementVNode("br"),
        _createTextVNode(" мы уведомим вас по номеру на WhatsApp")
      ]),
      _createElementVNode("input", { type: "tel" }),
      _createElementVNode("button", { class: "btn _green1" }, "Сохранить")
    ], -1)),
    _createVNode(whatsAppBlock)
  ]))
}
}

})