import { IAuthState } from "./authModuleTypes";
import axios, { AxiosResponse } from "axios";
import { Module } from "vuex";

const authModule: Module<IAuthState, any> = {
  namespaced: true,
  state: {
    bearerToken: null,
    authParams: null,
    isShowWhatsApp: false,
    additionalInfo: {
      user_id: null,
      name: null,
      phone: null,
      email: null,
      avatar: null,
      is_showed_presentation: null,
    },
  },
  getters: {},
  mutations: {},
  actions: {
    async registraion(context) {
      context.state.authParams = location.search;
      const url: string =
        `https://rfm.botzavod.com/api/company-webhook` + location.search;

      return await axios({
        method: "GET",
        url: url,
      })
        .then((res: AxiosResponse) => {
          if (res.data.success) {
            return context.dispatch("getAuth");
          }
        })
        .catch((err) => {
          console.log(err.response.data.description);
          if (
            err.response.data.description ===
            "Компания подключена к другому приложению! По дополнительным вопросам обратитесь в поддержку."
          ) {
            context.commit(
              "openErrorBlock",
              { code: 401, text: null },
              { root: true }
            );
          }
        });
    },
    async getAuth(context) {
      context.state.authParams = location.search;
      const url: string =
        `https://rfm.botzavod.com/api/registration` + location.search;

      return await axios({
        method: "GET",
        url: url,
      }).then((res: AxiosResponse) => {
        console.log(res);
        context.state.bearerToken = res.data.bearer_token;
        context.dispatch('getAdditionalInfo')
      });
    },
    async getAdditionalInfo(context) {
      return await axios({
        url: "https://rfm.botzavod.com/api/get-company-contact-client",
        params: {
          salon_id: context.rootState.salon_id,
        },
        headers: {
          Authorization: `Bearer ${context.state.bearerToken}`,
        },
      }).then((data: AxiosResponse) => {
        context.state.additionalInfo = data.data.client
      });
    },
    async updateShowedPresentationStatus(context) {
      context.state.additionalInfo.is_showed_presentation = 1
      return await axios({
        method: 'POST',
        url: 'https://rfm.botzavod.com/api/update-presentation-status',
        data: {
          salon_id: context.rootState.salon_id,
        },
        headers: {
          Authorization: `Bearer ${context.state.bearerToken}`,
        },
      }).then((response:AxiosResponse) => {
        console.log(response)
        context.state.additionalInfo.is_showed_presentation = 1
      })
    }
  },
};

export default authModule;
