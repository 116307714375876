import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "clients-pagination__info" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "clients-pagination__btns" }
const _hoisted_4 = {
  key: 2,
  class: "clients-pagination__spacer"
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = {
  key: 3,
  class: "clients-pagination__spacer"
}

import { ref, computed, onMounted } from "vue";

// Определение свойств компонента

export default /*@__PURE__*/_defineComponent({
  __name: 'paginationBlock',
  props: {
    totalItems: {},
    itemsPerPage: {},
    currentItems: {},
    defaultPage: {},
    borderBtns: { type: Boolean },
    hideResultStr: { type: Boolean }
  },
  emits: ["getPage"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

// Определение событий, которые компонент может испускать
const emit = __emit;

// Текущая страница, инициализируется значением из пропа или 1
const currentPage = ref(props.defaultPage || 1);

// Общее количество страниц
const totalPages = computed(() =>
  Math.ceil(props.totalItems / props.itemsPerPage)
);

// Начальная видимая страница
const visibleStartPage = ref(1);

// Вычисление видимых страниц
const visiblePages = computed(() => {
  const pages = [];
  for (
    let i = visibleStartPage.value;
    i <= Math.min(visibleStartPage.value + 2, totalPages.value);
    i++
  ) {
    pages.push(i);
  }
  return pages;
});

// Индексы первого и последнего элемента на текущей странице
const firstItemIndex = computed(
  () => (currentPage.value - 1) * props.itemsPerPage + 1
);
const lastItemIndex = computed(() =>
  Math.min(currentPage.value * props.itemsPerPage, props.totalItems)
);

// Функция для перехода на указанную страницу
function goToPage(page: number) {
  currentPage.value = page;
  emit("getPage", page);

  visibleStartPage.value = Math.max(1, page - 1);
}

// Функция для показа предыдущих страниц
function showPrevPages() {
  visibleStartPage.value = Math.max(1, visibleStartPage.value - 3);
}

// Функция для показа следующих страниц
function showNextPages() {
  visibleStartPage.value = Math.min(
    totalPages.value - 2,
    visibleStartPage.value + 3
  );
}

// Вызов события getPage при монтировании компонента, если установлена дефолтная страница
onMounted(() => {
  if (props.defaultPage) {
    emit("getPage", currentPage.value);
    visibleStartPage.value = Math.max(1, currentPage.value - 1);
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _renderSlot(_ctx.$slots, "elements"),
    _createElementVNode("div", {
      class: "clients-pagination",
      style: _normalizeStyle(_ctx.borderBtns ? '' : 'border:none;')
    }, [
      _createElementVNode("div", _hoisted_1, [
        (!_ctx.hideResultStr)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, "Показаны результаты с " + _toDisplayString(firstItemIndex.value) + " по " + _toDisplayString(lastItemIndex.value) + " из " + _toDisplayString(props.totalItems), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_3, [
        (visibleStartPage.value > 1)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              onClick: showPrevPages,
              class: "clients-pagination__btn"
            }, " Назад "))
          : _createCommentVNode("", true),
        (visibleStartPage.value > 1)
          ? (_openBlock(), _createElementBlock("button", {
              key: 1,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (goToPage(1))),
              class: _normalizeClass([{ _active: currentPage.value === 1 }, "clients-pagination__btn"])
            }, " 1 ", 2))
          : _createCommentVNode("", true),
        (visibleStartPage.value > 1)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(visiblePages.value, (page) => {
          return (_openBlock(), _createElementBlock("button", {
            key: page,
            onClick: ($event: any) => (goToPage(page)),
            class: _normalizeClass([{ _active: currentPage.value === page }, "clients-pagination__btn"])
          }, _toDisplayString(page), 11, _hoisted_5))
        }), 128)),
        (visibleStartPage.value + 2 < totalPages.value)
          ? (_openBlock(), _createElementBlock("span", _hoisted_6))
          : _createCommentVNode("", true),
        (visibleStartPage.value + 2 < totalPages.value)
          ? (_openBlock(), _createElementBlock("button", {
              key: 4,
              onClick: _cache[1] || (_cache[1] = ($event: any) => (goToPage(totalPages.value))),
              class: _normalizeClass([{ _active: currentPage.value === totalPages.value }, "clients-pagination__btn"])
            }, _toDisplayString(totalPages.value), 3))
          : _createCommentVNode("", true),
        (visibleStartPage.value + 2 < totalPages.value)
          ? (_openBlock(), _createElementBlock("button", {
              key: 5,
              onClick: showNextPages,
              class: "clients-pagination__btn"
            }, " Вперед "))
          : _createCommentVNode("", true)
      ])
    ], 4)
  ]))
}
}

})