import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "segments-presentation" }
const _hoisted_2 = {
  key: 0,
  class: "segments-presentation__tariffs mb-20px"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "presentation-control__right" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "presentation-control__btns" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["href"]

import { useStore } from "vuex";
import presentationStep from "./presentation-step.vue";

import tarifCard from "@/components/tariffs/tarifCard.vue";
import { computed, ref } from "vue";
import router from "@/router";


export default /*@__PURE__*/_defineComponent({
  __name: 'segments-presentation',
  setup(__props) {

const store = useStore();

const allTariffs = computed(() => store.state.tarif.allTariffs);

const additionalInfo = computed(() => store.state.auth.additionalInfo);

let currentStep = ref(0);

function prevStep() {
  if (currentStep.value > 0) {
    currentStep.value--;
  }
}

function nextStep() {
  if (currentStep.value < 4) {
    currentStep.value++;
  } else {
    updateShowedPresentationStatus()
    router.push("/");
  }
}
function goTariffs() {
  updateShowedPresentationStatus()
  router.push("/tariffs");
}

function updateShowedPresentationStatus() {
  store.dispatch('auth/updateShowedPresentationStatus')
}

const presentationSteps = [
  {
    index: 1,
    title: "🎉 Сегменты уже работают над анализом вашего филиала!",
    subtitle: "Это займет всего несколько минут.",
    beforeListsText: `Мы отправили вам уведомление в WhatsApp на номер <span class="fw-500">+${additionalInfo.value.phone}.</span>`,
    firstList: {
      title: "Именно на этот номер мы будем:",
      list: [
        "Отправлять отчеты о работе сервиса.",
        "Отвечать на все ваши вопросы в режиме технической поддержки с 8:00 до 20:00 по московскому времени.",
      ],
    },
    secondList: {
      title: "Чтобы быть уверенными, что вы получите наши сообщения:",
      list: [
        "Напишите нам что-нибудь в ответ на это сообщение, чтобы мы знали, что оно дошло.",
        "Добавьте наш номер в свои контакты, чтобы не пропустить уведомления.",
      ],
    },
    afterListsText: `Если вы хотите сменить номер, напишите об этом в диалог с номера +${additionalInfo.value.phone}. <br>
    Или напрямую в техническую поддержку - <a target="_blank" href="${store.state.supportLink}" class="blue-link">ссылка</a>`,
    supBtnText: "Проверьте, всё ли верно:",
    btnText: "Да, всё верно",
  },
  {
    index: 2,
    title: '<span class="fw-900">⌗</span> Сегментация клиентов',
    subtitle:
      "Узнайте, как распределяются ваши клиенты по активности и частоте визитов.",
    img: "segments-slide.webp",
    supBtnText: "Все понятно?",
    btnText: "Идем дальше..",
  },
  {
    index: 3,
    title: "🔥 Реактивация клиентов, просто как - раз, два, три..",
    subtitle: "Верните холодных клиентов в ваше ядро!",
    firstList: {
      title: "Вам нужно всего лишь:",
      list: [
        "Запустить реактивацию в пару кликов. Настроить рассылки, используя готовые шаблоны сообщений.",
        "Автоматически отслеживать результаты. Система покажет, кто записался, пришел и оплатил.",
        "Получать точные метрики. Видеть движения по каждому сегменту в режиме реального времени.",
      ],
    },
    secondList: {
      title: "Пример использования:",
      list: [
        'Выберите сегмент, например, "В зоне риска".',
        "Настройте реактивацию: выберите канал (WhatsApp), используйте предложенный текст или напишите свой.",
        "Безопасно протестируйте на себе.",
        "Наблюдайте, как клиенты возвращаются: система автоматически обновит данные и покажет эффективность кампании.",
      ],
    },
    img: "segments-slide1.webp",
    supBtnText: "Уже готовы начать?",
    btnText: "Идем дальше..",
  },
  {
    index: 4,
    title: "💬 Как отправляются сообщения через ваш SMS-агрегатор?",
    subtitle:
      "Сегменты устанавливаются поверх вашего провайдера и не нарушают его работу!",
    firstList: {
      title: "Почему через ваш SMS-агрегатор?",
      list: [
        "Сервис Сегменты интегрируется с вашим каналом связи автоматически, чтобы гарантировать, что каждая отправка сообщений соответствует вашим правилам и бюджету.",
        "Мы не берём дополнительных комиссий за передачу данных в ваш агрегатор.",
        "Все все настройки для массовой рассылки и очередей отправки уточняйте у вашего Агрегатора.",
      ],
    },
    secondList: {
      title: "Ваши сообщения не отправляются прямо из YCLIENTS в WhatsApp?",
      list: [
        "Возможно ваш интегратор имеет специфические настройки. Уточните как именно отправить сообщение клиенту по каналу СМС. ",
        "Отключена интеграция в разделе Уведомления. Отключите и подключите заново чтобы обновить настройки. ",
        "Протестируйте отправку сообщения из карточки клиента. Отправьте сообщение по каналу СМС.",
      ],
    },
    img: "segments-slide2.webp",
    supBtnText: "Уже готовы начать?",
    btnText: "Последний шаг..",
  },
  {
    index: 5,
    title: '🎉 Добро пожаловать в "Сегменты"!',
    subtitle:
      "Ваша аналитика, автоматизация и эффективность — за несколько рублей в день!",
    supBtnText: "Благодарим за доверие",
    btnText: "Начать тестирование",
  },
];

const currentSupBtnText = computed(
  () => presentationSteps[currentStep.value].supBtnText
);
const currentBtnText = computed(
  () => presentationSteps[currentStep.value].btnText
);

const currentStepStr = computed(() => {
  return `Шаг ${currentStep.value + 1} из ${presentationSteps.length}`;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createVNode(presentationStep, {
      info: presentationSteps[0]
    }, null, 8, ["info"]), [
      [_vShow, _unref(currentStep) === 0]
    ]),
    _withDirectives(_createVNode(presentationStep, {
      info: presentationSteps[1]
    }, null, 8, ["info"]), [
      [_vShow, _unref(currentStep) === 1]
    ]),
    _withDirectives(_createVNode(presentationStep, {
      info: presentationSteps[2]
    }, null, 8, ["info"]), [
      [_vShow, _unref(currentStep) === 2]
    ]),
    _withDirectives(_createVNode(presentationStep, {
      info: presentationSteps[3]
    }, null, 8, ["info"]), [
      [_vShow, _unref(currentStep) === 3]
    ]),
    _withDirectives(_createVNode(presentationStep, {
      info: presentationSteps[4]
    }, {
      default: _withCtx(() => [
        _cache[0] || (_cache[0] = _createElementVNode("p", { class: "fw-500 mb-13px" }, "Тарифы и Новогодние Скидки", -1)),
        _cache[1] || (_cache[1] = _createElementVNode("p", { class: "mb-20px" }, " До 31 декабря 2024 года активируйте любой тариф со скидкой до 80%! ", -1)),
        (allTariffs.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(allTariffs.value, (tarif) => {
                return (_openBlock(), _createBlock(tarifCard, {
                  key: tarif,
                  tarif: tarif,
                  type: 'short',
                  onGoTariffs: goTariffs
                }, null, 8, ["tarif"]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["info"]), [
      [_vShow, _unref(currentStep) === 4]
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["presentation-control", [
        { 'move-top1': _unref(currentStep) === 1 },
        { 'move-top2': _unref(currentStep) === 3 },
      ]])
    }, [
      _createElementVNode("div", {
        class: "presentation-control__step",
        innerHTML: currentStepStr.value
      }, null, 8, _hoisted_3),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", {
          class: "presentation-control__text font-ital fw-500",
          innerHTML: currentSupBtnText.value
        }, null, 8, _hoisted_5),
        _createElementVNode("div", _hoisted_6, [
          (_unref(currentStep) > 0)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: "presentation-control__btn _prev",
                onClick: prevStep
              }, " Назад "))
            : _createCommentVNode("", true),
          _createElementVNode("button", {
            class: "presentation-control__btn",
            innerHTML: currentBtnText.value,
            onClick: nextStep
          }, null, 8, _hoisted_7)
        ]),
        _createElementVNode("a", {
          target: "_blank",
          href: _unref(store).state.supportLink,
          class: "blue-link"
        }, "Что-то непонятно? Напишите нам прямо в WhatsApp", 8, _hoisted_8)
      ])
    ], 2)
  ]))
}
}

})