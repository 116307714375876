<template>
  <lastUpdateTimer
    :bgTimer="'#fbcd00'"
    :interval="60000"
    :updateFunction="() => getReactivationsByPage(currentReactivationsPage)"
    :isTimerRunning="true"
    v-if="currentActivation === null"
    class="mb-10px"
  />

  <paginationBlock
    class="reactivation-list"
    v-if="currentActivation === null"
    @getPage="(pageNumber) => getReactivationsByPage(pageNumber)"
    :totalItems="store.state.reactivation.totalReactivations"
    :itemsPerPage="10"
    :defaultPage="1"
    :borderBtns="false"
    :hideResultStr="true"
  >
    <template v-slot:elements>
      <reactivation-table
        @openActivation="(reactivation) => openActivation(reactivation)"
      ></reactivation-table>
    </template>
  </paginationBlock>

  <reactivation-item-page
    v-else-if="currentActivation"
    :activation="currentActivation"
    @close="closeReactivationItem"
  >
    <template v-slot:top
      ><lastUpdateTimer
        :bgTimer="'#fbcd00'"
        :interval="60000"
        :updateFunction="getReactivationClientsByPage"
        :isTimerRunning="true"
    /></template>
  </reactivation-item-page>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import reactivationItemPage from "@/components/reactivation/reactivationItemPage.vue";
import reactivationTable from "@/components/reactivation/reactivationTable.vue";
import paginationBlock from "@/components/UI/bloks/paginationBlock.vue";
import lastUpdateTimer from "@/components/UI/bloks/lastUpdateTimer.vue";
import getCurrentUpdateDateStr from "@/assets/getCurrentUpdateDateStr";

const store = useStore();

const reactivationList = computed(
  () => store.state.reactivation.reactivationList
);

const isShowWhatsApp = computed(() => store.state.auth.isShowWhatsApp);

let currentActivation = ref(null);

function openActivation(activation) {
  currentActivation.value = activation;
}

function closeReactivationItem() {
  currentActivation.value = null;
}

const currentReactivationsPage = computed(
  () => store.state.reactivation.currentReactivationsPage
);

function getReactivationsByPage(pageNumber) {
  return store
    .dispatch("reactivation/getReactivations", pageNumber)
    .then(() => {
      return getCurrentUpdateDateStr();
    })
    .catch(() => {
      return getCurrentUpdateDateStr();
    });
}

function getReactivationClientsByPage() {
  console.log(currentActivation.value.id);
  return store
    .dispatch("reactivation/getReactivationClientsByPage", {
      reactivationId: currentActivation.value.id,
      page: store.state.reactivation.currentPageClientsByReact,
    })
    .then(() => {
      return getCurrentUpdateDateStr();
    });
}

const currentReactivationFilterStatusId = computed(
  () => store.state.reactivation.currentReactivationFilterStatusId
);

watch(currentReactivationFilterStatusId, () => {
  getReactivationsByPage(currentReactivationsPage.value);
});
</script>

<style lang="scss" scoped>
.reactivation-list {
  max-width: 1500px;
  min-height: 300px;
}
</style>
