import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "tarif-card__flag"
}
const _hoisted_2 = {
  key: 1,
  class: "tarif-card__finish",
  style: {"justify-content":"center"}
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }
const _hoisted_6 = {
  key: 3,
  class: "small-loader tarif-card__loader"
}
const _hoisted_7 = ["src"]
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "tarif-card__info" }
const _hoisted_10 = { class: "tarif-card__name" }
const _hoisted_11 = { class: "tarif-card__price" }
const _hoisted_12 = {
  key: 0,
  class: "tarif-card__btn"
}
const _hoisted_13 = ["href"]
const _hoisted_14 = {
  key: 1,
  class: "tarif-card__btn"
}
const _hoisted_15 = {
  key: 2,
  class: "text tarif-card__document"
}
const _hoisted_16 = {
  key: 3,
  class: "tarif-card__allprice"
}
const _hoisted_17 = {
  key: 4,
  class: "tarif-pay-info__row"
}
const _hoisted_18 = { class: "tarif-card__options" }

import { TypeTarif } from "@/store/modules/tariffs/tarifModuleTypes";
import { computed } from "vue";
import { useStore } from "vuex";
import getDeclension from "@/assets/getDeclension";
import getPayStatusDescr from "@/assets/getPayStatusDescr";
import payTimer from "@/components/UI/pay-taimer.vue";
type typeProps = {
  tarif: TypeTarif;
  type: string
};


export default /*@__PURE__*/_defineComponent({
  __name: 'tarifCard',
  props: {
    tarif: {},
    type: {}
  },
  emits: ['goTariffs'],
  setup(__props: any, { emit: __emit }) {

const emit = __emit

function goTariffs() {
  emit('goTariffs')
}
const store = useStore();
/* eslint-disable-next-line */
const props = __props;

const isShort = computed(() => props.type === 'short')

const currentTarif = computed(() => props.tarif);

const currentPayment = computed(() => store.state.payment.currentPayment);

const currentPaymentStatus = computed(
  () => store.state.payment.currentPaymentStatus
);

function resetPayInfo() {
  store.state.payment.currentPayment = null;
  store.state.payment.currentPaymentStatus = null;
  store.state.payment.currentPaymentTarifId = null;
  if (!store.state.payment.getStatusInterval) {
    clearInterval(store.state.payment.getStatusInterval)
  }
}

const isPayConfirming = computed(() => currentPaymentStatus.value === 'CONFIRMED')

const currentPaymentTarifId = computed(
  () => store.state.payment.currentPaymentTarifId
);

const payStatusDescr = computed(() =>
  getPayStatusDescr(currentPaymentStatus.value)
);

const isDisabled = computed(() => {
  if (currentPaymentTarifId.value) {
    if (props.tarif.tariff_id === currentPaymentTarifId.value) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
});

const failedStatusesArr = [
  "REFUNDING",
  "ASYNC_REFUNDING",
  "PARTIAL_REFUNDED",
  "REFUNDED",
  "CANCELED",
  "DEADLINE_EXPIRED",
  "ATTEMPTS_EXPIRED",
  "REJECTED",
  "AUTH_FAIL",
];
const awaitingStatusesArr = [
  "NEW",
  "FORM_SHOWED",
  "AUTHORIZING",
  "AUTHORIZED",
  "CONFIRMING",
];

const isCurrentTarifPayment = computed(
  () => props.tarif.tariff_id === currentPaymentTarifId.value
);

function getMonthStr(number: number): string {
  return getDeclension(number, "месяц", "месяца", "месяцев");
}
const priceMonth = computed(() => +currentTarif.value.price_month);
const priceFull = computed(() => +currentTarif.value.price_full);
const allMonth = computed(() => +currentTarif.value.period_months);
const mainMonths = computed(
  () => currentTarif.value.period_months - +currentTarif.value.free_months
);

const allMonthStr = computed(
  () => allMonth.value + " " + getMonthStr(currentTarif.value.period_months)
);
const tarifOptions = computed(() => currentTarif.value.options.split(","));

function createPayment(): void {
  store.dispatch("payment/createPaymentForTarif", props.tarif.tariff_id);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["tarif-card", { _disabled: isDisabled.value }])
  }, [
    (!isCurrentTarifPayment.value)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["tarif-card__finish", `_${currentTarif.value.free_months}`])
        }, [
          _createTextVNode(_toDisplayString(mainMonths.value) + " " + _toDisplayString(getMonthStr(mainMonths.value)) + " ", 1),
          (+currentTarif.value.free_months)
            ? (_openBlock(), _createElementBlock("span", _hoisted_1, "+ " + _toDisplayString(currentTarif.value.free_months) + " в подарок", 1))
            : _createCommentVNode("", true)
        ], 2))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (awaitingStatusesArr.includes(currentPaymentStatus.value))
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, "Ожидаем оплату"))
            : (currentPaymentStatus.value === 'CONFIRMED')
              ? (_openBlock(), _createElementBlock("span", _hoisted_4, "Оплата прошла успешно"))
              : (failedStatusesArr.includes(currentPaymentStatus.value))
                ? (_openBlock(), _createElementBlock("span", _hoisted_5, "Ошибка оплаты"))
                : _createCommentVNode("", true),
          (awaitingStatusesArr.includes(currentPaymentStatus.value))
            ? (_openBlock(), _createElementBlock("span", _hoisted_6))
            : (currentPaymentStatus.value === 'CONFIRMED')
              ? (_openBlock(), _createElementBlock("img", {
                  key: 4,
                  class: "tarif-card__icon _success",
                  src: require('@/assets/images/success-icon.svg'),
                  alt: ""
                }, null, 8, _hoisted_7))
              : (failedStatusesArr.includes(currentPaymentStatus.value))
                ? (_openBlock(), _createElementBlock("img", {
                    key: 5,
                    class: "tarif-card__icon _failed",
                    src: require('@/assets/images/failed-icon.svg'),
                    alt: ""
                  }, null, 8, _hoisted_8))
                : _createCommentVNode("", true)
        ])),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, "Всё включено / " + _toDisplayString(allMonthStr.value), 1),
      _createElementVNode("div", _hoisted_11, _toDisplayString(priceMonth.value.toLocaleString()) + " ₽/мес ", 1),
      (!isShort.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
            (!isCurrentTarifPayment.value)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  class: "btn _border-raduis-9px fw-600",
                  onClick: createPayment
                }, " Оплатить "))
              : (currentPayment.value && !isPayConfirming.value)
                ? (_openBlock(), _createElementBlock("a", {
                    key: 1,
                    class: "btn _border-raduis-9px _payment fw-600",
                    href: currentPayment.value.PaymentURL,
                    target: "_blank"
                  }, "Перейти к оплате", 8, _hoisted_13))
                : (_openBlock(), _createElementBlock("button", {
                    key: 2,
                    class: "btn _payment fw-600",
                    onClick: resetPayInfo
                  }, "Вернуться"))
          ]))
        : _createCommentVNode("", true),
      (isShort.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
            _createElementVNode("button", {
              class: "btn _border-raduis-9px fw-600",
              onClick: goTariffs
            }, " Перейти к тарифам ")
          ]))
        : _createCommentVNode("", true),
      (!isShort.value)
        ? (_openBlock(), _createElementBlock("p", _hoisted_15, _cache[0] || (_cache[0] = [
            _createTextVNode("Нажимая на кнопку \"Оплатить\", вы принимаете "),
            _createElementVNode("a", {
              href: "https://b24-7zji69.bitrix24site.ru/Terms_of_Use/",
              target: "_blank",
              class: "blue-link"
            }, "публичную оферту", -1)
          ])))
        : _createCommentVNode("", true),
      (!isShort.value && (allMonth.value > 1 && !isCurrentTarifPayment.value))
        ? (_openBlock(), _createElementBlock("div", _hoisted_16, _toDisplayString(priceFull.value) + "₽ за " + _toDisplayString(allMonthStr.value), 1))
        : _createCommentVNode("", true),
      (isCurrentTarifPayment.value && !isPayConfirming.value)
        ? (_openBlock(), _createElementBlock("span", _hoisted_17, [
            _cache[1] || (_cache[1] = _createElementVNode("span", { class: "fw-600" }, "Времени осталось:", -1)),
            _createVNode(payTimer, {
              class: "tarif-pay-info__timer",
              deathDate: currentPayment.value.RedirectDueDate
            }, null, 8, ["deathDate"])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_18, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tarifOptions.value, (option) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "tarif-card__option",
            key: option
          }, _toDisplayString(option), 1))
        }), 128))
      ])
    ])
  ], 2))
}
}

})