import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { useStore } from 'vuex';
import segmentsPresentation from '@/components/presentation/segments-presentation.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'presentationPage',
  setup(__props) {

const store = useStore();


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(segmentsPresentation, { class: "presentation-page" }))
}
}

})