<template>
  <div class="zebra-table">
    <div class="zebra-table__header">
      <div
        class="zebra-table__row"
        :style="`grid-template-columns:${gridColumns};`"
      >
        <div
          class="zebra-table__col"
          v-for="headerElement in headerElements"
          :key="headerElement"
        >
          {{ headerElement }}
        </div>
      </div>
    </div>
    <div class="zebra-table__rows">
      <div
        class="zebra-table__row"
        :id="idFieldName + `_` + element[idFieldName]"
        :style="`grid-template-columns:${gridColumns};`"
        :key="index"
        v-for="(element, index) in elements"
      >
        <div
          class="zebra-table__col"
          v-for="elementInfo in elementsOrder"
          :key="elementInfo"
          v-html="getElementValue(element, elementInfo)"
        >
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useStore } from "vuex";

type TypeProps = {
  headerElements: string[];
  elements: any[];
  gridColumns: string;
  elementsOrder: string[];
  idFieldName?: string,
};

const props = defineProps<TypeProps>();

function isObject(value) {
  return value !== null && typeof value === "object" && !Array.isArray(value);
}
function getElementValue(element, elementInfo) {
  if (isObject(elementInfo)) {
    const result = elementInfo.func(element[elementInfo.value])

    if (typeof result === 'boolean') {
      return `<div class="${result? 'i-success': 'i-failed'}"></div>`
    } else {
      return result
    }
  } else {
    return element[elementInfo]
  }
}
</script>

<style lang="scss" scoped>
.zebra-table {
  &__header {
    background: #f7f9fc;
    font-weight: 500;
  }

  &__row {
    display: grid;

    grid-gap: 10px;
    padding: 14px 24px;
    align-items: center;
    cursor: pointer;

    &:nth-child(even) {
      background-color: #f7f6f6;
    }
  }

  &__col {
  }

  &__rows {
  }
}
</style>