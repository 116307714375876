import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "main-loader" }
const _hoisted_2 = {
  key: 0,
  class: "error-block"
}
const _hoisted_3 = { class: "tit-h1" }
const _hoisted_4 = {
  key: 0,
  class: "text"
}
const _hoisted_5 = { class: "fw-600" }
const _hoisted_6 = ["href"]
const _hoisted_7 = {
  key: 1,
  class: "main-pres-block"
}

import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import segHeader from "@/components/segHeader.vue";
import { onMounted, computed, watch } from "vue";
import exampleTableResponse from "@/assets/exampleTableResponse";

import segPresentation from "@/components/presentation/segments-presentation.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const store = useStore();

const route = useRoute();
const router = useRouter();

const additionalInfo = computed(() => store.state.auth.additionalInfo);
const isShowPresentation = computed(
  () => additionalInfo.value.is_showed_presentation === 0
);

const mainLoaderState = computed(() => store.state.mainLoader);
const notitficationBlock = computed(() => store.state.notitficationBlock);
const currentNotificationStyles = computed(() => {
  const styles = notitficationBlock.value.styles[notitficationBlock.value.type];
  if (notitficationBlock.value.text) {
    return `background-color:${styles.bg}; color: ${styles.color}; opacity: 1;`;
  } else {
    return "";
  }
});

function parseQueryString(queryString) {
  // Удаляем знак вопроса в начале строки, если он есть
  if (queryString.startsWith("?")) {
    queryString = queryString.slice(1);
  }

  // Разбиваем строку на пары ключ-значение
  const pairs = queryString.split("&");
  const result = {};

  pairs.forEach((pair) => {
    const [key, value] = pair.split("=");
    // Декодируем ключ и значение и добавляем их в объект
    result[decodeURIComponent(key)] = decodeURIComponent(value);
  });

  return result;
}

const appParams = computed(() => parseQueryString(store.state.auth.authParams));

let appsId = {
  segmentsPlusWa: 8316,
  segments: 12308,
};

const linkInAnotherApp = computed(() => {
  const appId = appParams.value.application_id === 8316 ? 12308 : 8316;
  return `https://yclients.com/appstore/${appParams.value.salon_id}/applications/${appId}/info`;
});

const anotherAppName = computed(() => {
  return appParams.value.application_id === 8316
    ? "Сегменты + WhatsApp"
    : "Сегменты";
});

onMounted(() => {
  store.commit(
    "segments/modifyAndSetSegments",
    exampleTableResponse.company_rfm
  );
  // store.commit("setMainLoader", true);
  const params = {};
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  console.log(queryString, urlParams);
  urlParams.forEach((value, key) => {
    params[key] = value;
  });

  if (urlParams.get("salon_id")) {
    store.state.salon_id = +params.salon_id;
    store.state.auth.isShowWhatsApp = +params.application_id === 8316;

    store.dispatch("auth/registraion").then(() => {
      Promise.all([
        store.dispatch("tarif/getAllTariffs"),
        store.dispatch("tarif/getCurrentTarif"),
      ]).finally(() => {
        store.commit("setMainLoader", false);
        if (!store.state.tarif?.currentTarif?.active) {
          router.push("/tariffs");
        } else {
          store.dispatch("segments/getSegmentsTable");
        }
      });
    });
  }

  window.addEventListener("message", (event) => {
    if (event.data === "scrollUp") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  });
});

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["container", { _loader: mainLoaderState.value }])
    }, [
      _createVNode(segHeader),
      _createVNode(_component_router_view)
    ], 2),
    _withDirectives(_createElementVNode("div", _hoisted_1, null, 512), [
      [_vShow, mainLoaderState.value]
    ]),
    (_unref(store).state.errorBlock.show)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, "Ошибка " + _toDisplayString(_unref(store).state.errorBlock.code), 1),
          (!_unref(store).state.errorBlock.text)
            ? (_openBlock(), _createElementBlock("p", _hoisted_4, [
                _cache[0] || (_cache[0] = _createElementVNode("span", { class: "fw-600" }, "Уважаемый клиент!", -1)),
                _cache[1] || (_cache[1] = _createTextVNode()),
                _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1)),
                _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)),
                _cache[4] || (_cache[4] = _createTextVNode(" На данный момент у Вас уже подключено наше приложение: ")),
                _createElementVNode("span", _hoisted_5, "«" + _toDisplayString(anotherAppName.value) + "»", 1),
                _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1)),
                _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1)),
                _cache[7] || (_cache[7] = _createTextVNode(" Для подключения нового приложения необходимо сначала отключить уже установленное приложение. ")),
                _cache[8] || (_cache[8] = _createElementVNode("br", null, null, -1)),
                _cache[9] || (_cache[9] = _createElementVNode("br", null, null, -1)),
                _cache[10] || (_cache[10] = _createTextVNode(" Это связано с техническими ограничениями: одновременно может быть подключено только одно из приложений, иначе работа системы будет нарушена. ")),
                _cache[11] || (_cache[11] = _createElementVNode("br", null, null, -1)),
                _cache[12] || (_cache[12] = _createElementVNode("br", null, null, -1)),
                _cache[13] || (_cache[13] = _createTextVNode(" Мы рекомендуем продолжить использование уже подключенного приложения, так как оно функционирует в полном объеме. ")),
                _cache[14] || (_cache[14] = _createElementVNode("br", null, null, -1)),
                _cache[15] || (_cache[15] = _createElementVNode("br", null, null, -1)),
                _cache[16] || (_cache[16] = _createTextVNode(" Если Вам всё же необходимо сменить приложение, пожалуйста, сначала отключите текущее через настройки интеграций, а затем повторите попытку подключения. ")),
                _cache[17] || (_cache[17] = _createElementVNode("br", null, null, -1)),
                _cache[18] || (_cache[18] = _createElementVNode("br", null, null, -1)),
                _cache[19] || (_cache[19] = _createTextVNode(" Если у Вас возникли вопросы или нужна помощь, наша поддержка готова помочь Вам в любое время. С уважением, Команда поддержки «Сегменты» "))
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("a", {
            href: linkInAnotherApp.value,
            class: "blue-link",
            target: "_top"
          }, "Перейти к подключенному приложению", 8, _hoisted_6),
          _cache[20] || (_cache[20] = _createElementVNode("br", null, null, -1)),
          _cache[21] || (_cache[21] = _createElementVNode("br", null, null, -1)),
          _cache[22] || (_cache[22] = _createElementVNode("a", {
            href: "https://t.me/Segment_support_bot",
            target: "_top",
            class: "blue-link"
          }, " Написать в поддержку", -1))
        ]))
      : _createCommentVNode("", true),
    (isShowPresentation.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createVNode(segPresentation)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})