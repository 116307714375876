import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, vShow as _vShow, withDirectives as _withDirectives, toDisplayString as _toDisplayString, vModelText as _vModelText, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "reactivation-test" }
const _hoisted_2 = { class: "reactivation-test__inner" }
const _hoisted_3 = { class: "text-small" }
const _hoisted_4 = ["disabled"]
const _hoisted_5 = ["disabled"]
const _hoisted_6 = {
  key: 0,
  class: "small-loader"
}

import { computed, ref } from "vue";
import { useStore } from "vuex";


export default /*@__PURE__*/_defineComponent({
  __name: 'reactivationTestForm',
  setup(__props) {

const store = useStore();

const reactivationTestNumber = computed({
  get: () => store.state.reactivation.reactivationTestNumber,
  set: (value: string) => {
    store.commit("reactivation/setReactivationTestNumber", value);
  },
});

function formatPhoneNumber(event: Event) {
  const input = event.target as HTMLInputElement;
  let value = input.value.replace(/\D/g, ""); // Удаляем все нечисловые символы

  if (value && value[0] !== "0") {
    value = "+" + value; // Добавляем "+" в начале, если его нет
  }

  reactivationTestNumber.value = value;
}

let error = ref('')

const reactivationTestLoading = computed(() => store.state.reactivation.reactivationTestLoading)
const reactivationTestStatus = computed({
  get: () => store.state.reactivation.reactivationTestStatus,
  set: (value:string) => store.state.reactivation.reactivationTestStatus = value
})

function createReactivationTest() {
  if (reactivationTestNumber.value.length >= 10) {
    store.dispatch('reactivation/createReactivationTest')
    error.value = ''
    reactivationTestStatus.value = ''
  } else {
    error.value = 'Некорректный номер'
    reactivationTestStatus.value = ''
  }
  
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "reactivation-test__title tit-h3 fw-500" }, " Обязательно протестируйте шаблон ", -1)),
      _withDirectives(_createElementVNode("div", _hoisted_3, "прежде чем запустить рассылку", 512), [
        [_vShow, !_unref(error) && !reactivationTestStatus.value]
      ]),
      _withDirectives(_createElementVNode("div", { class: "text-small text-error" }, _toDisplayString(_unref(error)), 513), [
        [_vShow, _unref(error) && !reactivationTestStatus.value]
      ]),
      _withDirectives(_createElementVNode("div", {
        class: "text-small",
        style: {"color":"rgb(55 170 145)"}
      }, _toDisplayString(reactivationTestStatus.value), 513), [
        [_vShow, reactivationTestStatus.value]
      ]),
      _withDirectives(_createElementVNode("input", {
        class: _normalizeClass([_unref(error) ? '_error' : '', "default-input"]),
        placeholder: "Номер телефона тестировщика",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((reactivationTestNumber).value = $event)),
        type: "tel",
        onInput: formatPhoneNumber,
        disabled: reactivationTestLoading.value
      }, null, 42, _hoisted_4), [
        [_vModelText, reactivationTestNumber.value]
      ]),
      _createElementVNode("button", {
        disabled: reactivationTestLoading.value,
        class: "btn reactivation-test__btn",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (createReactivationTest()))
      }, [
        _cache[2] || (_cache[2] = _createElementVNode("span", null, "Тест", -1)),
        (reactivationTestLoading.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6))
          : _createCommentVNode("", true)
      ], 8, _hoisted_5)
    ])
  ]))
}
}

})