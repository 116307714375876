import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { computed } from "vue";
import { useStore } from "vuex";
import months from '@/assets/months.ts'


export default /*@__PURE__*/_defineComponent({
  __name: 'tarifStatus',
  setup(__props) {

const store = useStore();

const currentTarif = computed(() => store.state.tarif.currentTarif);

const endDateStr = computed(() =>
  formatEndDate(currentTarif.value.license_expiry)
);

const isTarifActive = computed(() => currentTarif.value.active)

function formatEndDate(endDateStr: string): string {
const endDate = new Date(endDateStr);

const formattedDate = `${endDate.getDate().toString().padStart(2, '0')}.${(endDate.getMonth() + 1).toString().padStart(2, '0')}.${endDate.getFullYear()} ${endDate.getHours().toString().padStart(2, '0')}:${endDate.getMinutes().toString().padStart(2, '0')}`;

return `Сервис активен до ${formattedDate}`;
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["tarif-status", isTarifActive.value ? `_active` : ''])
  }, _toDisplayString(isTarifActive.value ? endDateStr.value : 'Сервис не активен, приобретите лицензию'), 3))
}
}

})