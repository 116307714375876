import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "tariffs" }
const _hoisted_2 = { class: "tariffs__status" }
const _hoisted_3 = {
  key: 0,
  class: "tariffs__banner"
}
const _hoisted_4 = {
  key: 1,
  class: "tariffs-list"
}

import { useStore } from "vuex";
import tarifCard from "@/components/tariffs/tarifCard.vue";
import tarifStatus from "@/components/tariffs/tarifStatus.vue";
import { computed, onUnmounted } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'tariffsPage',
  setup(__props) {

const store = useStore();

const allTariffs = computed(() => store.state.tarif.allTariffs);

const currentTarif = computed(() => store.state.tarif.currentTarif);

const showTariffBanner = computed({
  get: () => store.state.tarif.showTariffBanner,
  set: (newValue) => store.commit("tarif/setTariffBanner", newValue),
});

onUnmounted(() => {
  showTariffBanner.value = false;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (currentTarif.value)
        ? (_openBlock(), _createBlock(tarifStatus, { key: 0 }))
        : _createCommentVNode("", true)
    ]),
    (allTariffs.value && showTariffBanner.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[0] || (_cache[0] = [
          _createTextVNode(" На данный момент у вас активирован тестовый тариф, в котором нельзя создавать реактивации и скачивать клиентов. "),
          _createElementVNode("br", null, null, -1),
          _createTextVNode(" Для доступа ко всем функциям, пожалуйста, приобретите полный тариф. ")
        ])))
      : _createCommentVNode("", true),
    (allTariffs.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(allTariffs.value, (tarif) => {
            return (_openBlock(), _createBlock(tarifCard, {
              key: tarif,
              tarif: tarif,
              type: 'full'
            }, null, 8, ["tarif"]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})