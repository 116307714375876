import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "awaiting-segments" }
const _hoisted_2 = { class: "awaiting-segments__list" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "fw-600" }

import { computed } from "vue";
import { useStore } from "vuex";
import baseSegment from "@/components/main-page/segments/baseSegment.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'awaitingSegments',
  emits: ["setAwaitSegment"],
  setup(__props, { emit: __emit }) {

const emit = __emit;

const modifySegments = computed(() => store.state.segments.modifySegments);

const isTableLoading = computed(() => store.state.segments.isTableLoading);

const awaitngSegments = computed(
  () => store.state.segments.modifySegments.awaitingSegments
);

const awaitSegment = computed(
  () => store.state.segments.modifySegments.awaitSegment
);

const store = useStore();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(awaitngSegments.value, (segment, number) => {
        return (_openBlock(), _createElementBlock("div", {
          onClick: ($event: any) => (emit('setAwaitSegment', segment)),
          class: _normalizeClass(["awaiting-segments__item base-segment", {
          _hover: !isTableLoading.value,
          '_border-top-radius': number === 0,
          '_border-bottom-left-radius': number === 2,
        }]),
          key: segment.index,
          style: _normalizeStyle(`background-color:${segment.background_color}; height: ${
          (segment.max_frequency - segment.min_frequency) * 20
        }%`)
        }, [
          _createElementVNode("div", null, _toDisplayString(segment.name), 1),
          _createElementVNode("div", {
            class: _normalizeClass(["awaiting-segments__stats fs-18px", { 'loading-text': isTableLoading.value }])
          }, [
            _createElementVNode("span", _hoisted_4, _toDisplayString(segment.total_clients), 1),
            _createTextVNode(" — " + _toDisplayString(segment.total_clients_percent) + "% ", 1)
          ], 2),
          _cache[1] || (_cache[1] = _createElementVNode("div", null, "На сумму", -1)),
          _createElementVNode("div", {
            class: _normalizeClass(["segments-item__check fw-600 fs-18px", { 'loading-text': isTableLoading.value }])
          }, _toDisplayString((+segment.check).toLocaleString()) + " ₽ ", 3)
        ], 14, _hoisted_3))
      }), 128))
    ]),
    _cache[4] || (_cache[4] = _createElementVNode("div", { class: "awaiting-segments__title fw-600" }, "Зона ожидания", -1)),
    _createVNode(baseSegment, {
      class: _normalizeClass(["awaiting-segments__all", { '_hover _border-radius': !isTableLoading.value }]),
      segment: awaitSegment.value,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('setAwaitSegment', awaitSegment.value)))
    }, {
      default: _withCtx(() => [
        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "base-segment__name mt-11px" }, "Оперативные записи", -1)),
        _createElementVNode("div", {
          class: _normalizeClass(["base-segment__stats", { 'loading-text': isTableLoading.value }])
        }, _cache[2] || (_cache[2] = [
          _createElementVNode("span", { class: "fw-500" }, "XXX", -1),
          _createTextVNode(" – XXX ")
        ]), 2)
      ]),
      _: 1
    }, 8, ["class", "segment"])
  ]))
}
}

})