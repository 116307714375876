import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "header" }
const _hoisted_2 = ["href"]

import { computed } from "vue";
import { useStore } from "vuex";

type navItem = {
  name: string;
  route: string;
  index: number;
  isRouterLink: boolean;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'segHeader',
  setup(__props) {

const store = useStore();

const currentTarif = computed(() => store.state.tarif.currentTarif);

const navList = computed<navItem[]>(() => {
  let result = [
    {
      name: "Поддержка",
      route: store.state.supportLink,
      isRouterLink: false,
      index: 6,
      show: true,
    },
    {
      name: "Презентация",
      route: '/presentation',
      isRouterLink: true,
      index: 3,
      show: true,
    },
    {
      name: "Тарифы",
      route: "/tariffs",
      isRouterLink: true,
      index: 5,
      show: true,
    },
  ];

  if (currentTarif.value && currentTarif.value.active) {
    result = [
      ...result,
      {
        name: "Сегменты",
        route: "/",
        isRouterLink: true,
        index: 0,
        show: true,
      },
      {
        name: "Реактивация",
        route: "/reactivation",
        isRouterLink: true,
        index: 1,
        show: true,
      },
      {
        name: "WhatsApp",
        route: "/whatsapp",
        isRouterLink: true,
        index: 2,
        show: store.state.auth.isShowWhatsApp,
      },
      {
        name: "Смарт",
        route: "/smart",
        isRouterLink: true,
        index: 2,
        show: false,
      },
      {
        name: "Отчеты",
        route: "/reports",
        isRouterLink: true,
        index: 3,
        show: false,
      },
      {
        name: "Настройки",
        route: "/settings",
        isRouterLink: true,
        index: 4,
        show: false,
      },
    ];
  }

  return result.sort((a, b) => a.index - b.index);
});

function reloadPage():void {
  location.href = location.origin + '/' + store.state.auth.authParams
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(navList.value.filter((i) => i.show), (item) => {
      return (_openBlock(), _createElementBlock("div", {
        key: item.name,
        class: "menu-link"
      }, [
        (item.isRouterLink)
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 0,
              to: item.route,
              class: "menu-link__item"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(item.name), 1)
              ]),
              _: 2
            }, 1032, ["to"]))
          : _createCommentVNode("", true),
        (!item.isRouterLink)
          ? (_openBlock(), _createElementBlock("a", {
              key: 1,
              href: item.route,
              class: "menu-link__item",
              target: "_blank"
            }, _toDisplayString(item.name), 9, _hoisted_2))
          : _createCommentVNode("", true)
      ]))
    }), 128)),
    _createElementVNode("button", {
      class: "version",
      title: "Обновить приложение",
      onClick: reloadPage
    }, "Версия " + _toDisplayString(_unref(store).state.version), 1)
  ]))
}
}

})