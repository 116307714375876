import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "whatsapp-block" }
const _hoisted_2 = { class: "whatsapp-block__inner" }
const _hoisted_3 = { class: "whatsapp-block__instruction" }
const _hoisted_4 = {
  key: 0,
  class: "whatsapp-ul"
}
const _hoisted_5 = { class: "whatsapp-ul__icon" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "whatsapp-ul__icon" }
const _hoisted_8 = ["src"]
const _hoisted_9 = {
  key: 1,
  class: "whatsapp-ul"
}
const _hoisted_10 = { class: "whatsapp-ul__icon" }
const _hoisted_11 = ["src"]
const _hoisted_12 = { class: "whatsapp-ul__icon" }
const _hoisted_13 = ["src"]

import { useStore } from "vuex";
import whatsappQr from "@/components/whatsapp/whatsappQr.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'whatsappBlock',
  setup(__props) {

const store = useStore();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[21] || (_cache[21] = _createElementVNode("div", { class: "tit-h3" }, [
          _createTextVNode(" Для отправки и приема сообщений Вам необходимо "),
          _createElementVNode("br"),
          _createTextVNode(" авторизовать наш сервер как WhatsApp Web. ")
        ], -1)),
        true
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("ul", null, [
                _cache[7] || (_cache[7] = _createElementVNode("li", null, "Откройте WhatsApp на своём телефоне", -1)),
                _createElementVNode("li", null, [
                  _cache[0] || (_cache[0] = _createTextVNode(" Нажмите ")),
                  _cache[1] || (_cache[1] = _createElementVNode("span", { class: "fw-500" }, "Меню", -1)),
                  _createElementVNode("span", _hoisted_5, [
                    _createElementVNode("img", {
                      src: require('@/assets/images/dots-wa.svg'),
                      alt: ""
                    }, null, 8, _hoisted_6)
                  ]),
                  _cache[2] || (_cache[2] = _createTextVNode(" или ")),
                  _cache[3] || (_cache[3] = _createElementVNode("span", { class: "fw-500" }, "Настройки", -1)),
                  _createElementVNode("span", _hoisted_7, [
                    _createElementVNode("img", {
                      src: require('@/assets/images/settings-wa.svg'),
                      alt: ""
                    }, null, 8, _hoisted_8)
                  ]),
                  _cache[4] || (_cache[4] = _createTextVNode(" и выберите ")),
                  _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1)),
                  _cache[6] || (_cache[6] = _createElementVNode("span", { class: "fw-500" }, "Связные устройства", -1))
                ]),
                _cache[8] || (_cache[8] = _createElementVNode("li", null, [
                  _createTextVNode(" Нажмите "),
                  _createElementVNode("span", { class: "fw-500" }, "Привязка устройства")
                ], -1)),
                _cache[9] || (_cache[9] = _createElementVNode("li", null, "Наведите свой экран на этот экран, чтобы считать код", -1))
              ])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _cache[20] || (_cache[20] = _createElementVNode("p", { class: "mb-3" }, "Чтобы сменить устройство / номер", -1)),
              _createElementVNode("ul", null, [
                _createElementVNode("li", null, [
                  _cache[10] || (_cache[10] = _createTextVNode(" Нажмите ")),
                  _cache[11] || (_cache[11] = _createElementVNode("span", { class: "fw-500" }, "Меню", -1)),
                  _createElementVNode("span", _hoisted_10, [
                    _createElementVNode("img", {
                      src: require('@/assets/images/dots-wa.svg'),
                      alt: ""
                    }, null, 8, _hoisted_11)
                  ]),
                  _cache[12] || (_cache[12] = _createTextVNode(" или ")),
                  _cache[13] || (_cache[13] = _createElementVNode("span", { class: "fw-500" }, "Настройки", -1)),
                  _createElementVNode("span", _hoisted_12, [
                    _createElementVNode("img", {
                      src: require('@/assets/images/settings-wa.svg'),
                      alt: ""
                    }, null, 8, _hoisted_13)
                  ]),
                  _cache[14] || (_cache[14] = _createTextVNode(" и выберите ")),
                  _cache[15] || (_cache[15] = _createElementVNode("br", null, null, -1)),
                  _cache[16] || (_cache[16] = _createElementVNode("span", { class: "fw-500" }, "Связные устройства", -1))
                ]),
                _cache[17] || (_cache[17] = _createElementVNode("li", null, [
                  _createTextVNode(" Выберите "),
                  _createElementVNode("span", { class: "fw-500" }, "привязанное устройство"),
                  _createTextVNode(" / нажмите ВЫХОД ")
                ], -1)),
                _cache[18] || (_cache[18] = _createElementVNode("li", null, "после выхода перезагрузите страницу с QR кодом", -1)),
                _cache[19] || (_cache[19] = _createElementVNode("li", null, "Привяжите новое устройство", -1))
              ])
            ]))
      ]),
      _createVNode(whatsappQr),
      _cache[22] || (_cache[22] = _createElementVNode("p", { class: "text" }, " Внимание - приложение WhatsApp сканирует QR код дважды за доли секунды / не убирайте телефон от QR кода слишком быстро ", -1))
    ])
  ]))
}
}

})