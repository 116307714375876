import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = { class: "si-row mb-13px" }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = { class: "si-row mb-13px" }
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = { class: "si-row mb-13px" }
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = {
  key: 0,
  class: "si-row mb-13px"
}
const _hoisted_15 = ["innerHTML"]
const _hoisted_16 = {
  key: 1,
  class: "si-row"
}
const _hoisted_17 = ["innerHTML"]
const _hoisted_18 = {
  key: 1,
  class: "btn _red"
}
const _hoisted_19 = {
  key: 2,
  class: "btn _blue"
}
const _hoisted_20 = { key: 3 }
const _hoisted_21 = { class: "reactivation-text" }
const _hoisted_22 = {
  key: 0,
  class: "fw-500 fs-12px reactivation-text__message"
}

import { useStore } from "vuex";
import pageWithSectionsHeader from "@/components/UI/bloks/pageWithSectionsHeader.vue";
import zebraTable from "@/components/UI/bloks/zebraTable.vue";
import paginationBlock from "@/components/UI/bloks/paginationBlock.vue";
import { computed, onMounted, ref } from "vue";
import getSegmentByIndex from "@/functions/getSegmentByIndex";

import confirmAction from '@/components/UI/bloks/confirmAction.vue'

const headerGridColWithWa = "140px 150px repeat(8, minmax(0, 1fr))";
const headerGridColWithoutWa = "140px 150px repeat(5, minmax(0, 1fr))";


export default /*@__PURE__*/_defineComponent({
  __name: 'reactivationItemPage',
  props: {
  activation: Object,
},
  setup(__props, { emit: __emit }) {

const headerSectionsBgs = [
  "rgba(109, 207, 27, 0.25)",
  "rgba(205, 247, 255, 1)",
  "rgba(254, 228, 171, 1)",
];

const tableHeaderWithWa = [
  "Номер",
  "Имя",
  "Отправлено",
  "Доставлено",
  "Прочитано",
  "Записался",
  "Пришел",
  "Оплатил",
  "Средний чек",
  "Выручка",
];
const tableHeaderWithoutWa = [
  "Номер",
  "Имя",
  "Записался",
  "Пришел",
  "Оплатил",
  "Средний чек",
  "Выручка",
];

const elementsOrder = [
  "phone",
  "name",
  { value: "record_status", func: (value) => statusOrder1(value) },
  { value: "record_status", func: (value) => statusOrder2(value) },
  { value: "payment_status", func: (value) => statusOrderPayment(value) },
  { value: "average_check", func: (value) => getModifySum(value) },
  { value: "amount", func: (value) => getModifySum(value) },
];

function statusOrder1(statusOrder) {
  return statusOrder > -2;
}
function statusOrder2(statusOrder) {
  return statusOrder >= 1;
}
function statusOrderPayment(statusPayment) {
  return !!statusPayment;
}
function getModifySum(number) {
  return number.toLocaleString() + " ₽";
}

const props = __props;
/* eslint-disable-next-line */
const emit = __emit;

const store = useStore();

const isShowWhatsApp = computed(() => store.state.auth.isShowWhatsApp);

const currentSegmentName = computed(() => {
  const modifySegments = store.state.segments.modifySegments;
  const segmentIndex = props.activation.segment_id;
  return getSegmentByIndex(modifySegments, segmentIndex).name;
});

const loadingClientByCurrentReactivation = computed(
  () => store.state.reactivation.loadingClientByCurrentReactivation
);

const isConfirmStopWatchingShow = ref(false)

function stopWatching() {
  store.dispatch("reactivation/stopWatchingReactivation", props.activation.id);
  emit("close");
}
function closePage() {
  emit("close");
}

const currentClientsPage = computed(
  () => store.state.reactivation.clientByCurrentReactivation
);

const isReactivationStopped = computed(
  () => props.activation.goal.status_id < 1
);

const statusStr = computed(() => props.activation.goal.description);
const dateTime = computed(() => {
  const dateTimeString = props.activation.start_date;
  const [datePart, timePart] = dateTimeString.split(" ");
  const [year, month, day] = datePart.split("-");
  const [hours, minutes] = timePart.split(":");

  return {
    date: `${day}.${month}.${year}`,
    time: `${hours}:${minutes}`,
  };
});

function getPluralForm(number, forms) {
  const n = Math.abs(number) % 100;
  const n1 = n % 10;
  if (n > 10 && n < 20) {
    return forms[2];
  }
  if (n1 > 1 && n1 < 5) {
    return forms[1];
  }
  if (n1 === 1) {
    return forms[0];
  }
  return forms[2];
}

const setting1Str = computed(() =>
  props.activation.goal.ignore_stop
    ? "Отправляем при согласии"
    : "Для всех клиентов сегмента"
);
const setting2Str = computed(() =>
  props.activation.channel[0] === "SMS"
    ? "Всем отправить по WhatsApp"
    : `Всем отправить по ${props.activation.channel[0]}`
);
const setting3Str = computed(() => "Цель - Клиент пришёл и оплатил");

const totalClient = computed(() => props.activation.total_clients);

const totalClientsStr = computed(
  () =>
    totalClient.value +
    " " +
    getPluralForm(totalClient, ["клиент", "клиента", "клиентов"])
);

const textStr = computed(() => props.activation.text || null);

const timeRemainingFinish = computed(() =>
  getTimeRemaining(props.activation.goal.finish_date)
);
const timeRemainingFinishMailing = computed(() =>
  getTimeRemaining(props.activation.goal.finish_mailing_date)
);

const currentIdStr = computed(() => {
  return `ID реактивации - #${props.activation.id}`;
});

function getTimeRemaining(targetDateStr) {
  const targetDate = new Date(targetDateStr);
  const currentDate = new Date();

  const diffInMilliseconds = targetDate - currentDate;

  if (diffInMilliseconds <= 0) {
    return "Дата уже прошла";
  }

  const diffInHours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
  const days = Math.floor(diffInHours / 24);
  const hours = diffInHours % 24;

  const dayForms = ["день", "дня", "дней"];
  const hourForms = ["час", "часа", "часов"];

  const dayString = `${days} ${getPluralForm(days, dayForms)}`;
  const hourString = `${hours} ${getPluralForm(hours, hourForms)}`;

  return `${dayString} и ${hourString}`;
}

function getPage(pageNumber) {
  store.dispatch("reactivation/getReactivationClientsByPage", {
    page: pageNumber,
    reactivationId: props.activation.id,
  });
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(pageWithSectionsHeader, {
      onClose: closePage,
      sectionsColor: headerSectionsBgs,
      class: "reactivation-item-page"
    }, {
      top: _withCtx(() => [
        _renderSlot(_ctx.$slots, "top")
      ]),
      header1: _withCtx(() => [
        _createElementVNode("p", {
          class: "si-tit",
          innerHTML: statusStr.value
        }, null, 8, _hoisted_1),
        _createElementVNode("p", {
          class: "si-tit",
          innerHTML: dateTime.value.date
        }, null, 8, _hoisted_2),
        _createElementVNode("p", {
          class: "si-tit",
          innerHTML: dateTime.value.time
        }, null, 8, _hoisted_3),
        _createElementVNode("p", {
          class: "fs-12px fw-500",
          innerHTML: currentIdStr.value
        }, null, 8, _hoisted_4)
      ]),
      header2: _withCtx(() => [
        _cache[3] || (_cache[3] = _createElementVNode("p", { class: "si-tit" }, "Начальные настройки", -1)),
        _createElementVNode("p", {
          class: "fs-12px fw-500 mb-15px",
          innerHTML: setting1Str.value
        }, null, 8, _hoisted_5),
        _createElementVNode("p", {
          class: "fs-12px fw-500 mb-15px",
          innerHTML: setting2Str.value
        }, null, 8, _hoisted_6),
        _createElementVNode("p", {
          class: "fs-12px fw-500",
          innerHTML: setting3Str.value
        }, null, 8, _hoisted_7)
      ]),
      header3: _withCtx(() => [
        _createElementVNode("p", _hoisted_8, [
          _cache[4] || (_cache[4] = _createElementVNode("span", null, "Сегмент", -1)),
          _createElementVNode("span", {
            class: "fw-500",
            innerHTML: currentSegmentName.value
          }, null, 8, _hoisted_9)
        ]),
        _createElementVNode("p", _hoisted_10, [
          _cache[5] || (_cache[5] = _createElementVNode("span", null, "Всего в рассылке", -1)),
          _createElementVNode("span", {
            class: "fw-500",
            innerHTML: totalClientsStr.value
          }, null, 8, _hoisted_11)
        ]),
        _createElementVNode("p", _hoisted_12, [
          _cache[6] || (_cache[6] = _createElementVNode("span", null, "Отслеживание цели", -1)),
          _createElementVNode("span", {
            class: "fw-500",
            innerHTML: timeRemainingFinish.value
          }, null, 8, _hoisted_13)
        ]),
        (isShowWhatsApp.value)
          ? (_openBlock(), _createElementBlock("p", _hoisted_14, [
              _cache[7] || (_cache[7] = _createElementVNode("span", null, "Отслеживание цели для ", -1)),
              _createElementVNode("span", {
                class: "fw-500",
                innerHTML: totalClientsStr.value
              }, null, 8, _hoisted_15)
            ]))
          : _createCommentVNode("", true),
        (isShowWhatsApp.value)
          ? (_openBlock(), _createElementBlock("p", _hoisted_16, [
              _cache[8] || (_cache[8] = _createElementVNode("span", null, "Окончание цели через", -1)),
              _createElementVNode("span", {
                class: "fw-500",
                innerHTML: timeRemainingFinishMailing.value
              }, null, 8, _hoisted_17)
            ]))
          : _createCommentVNode("", true)
      ]),
      btns: _withCtx(() => [
        (!isReactivationStopped.value)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "btn _yellow",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (isConfirmStopWatchingShow.value = true))
            }, " Завершить досрочно "))
          : _createCommentVNode("", true),
        (isShowWhatsApp.value)
          ? (_openBlock(), _createElementBlock("button", _hoisted_18, " Остановить рассылку "))
          : _createCommentVNode("", true),
        (isShowWhatsApp.value)
          ? (_openBlock(), _createElementBlock("button", _hoisted_19, " Возобновить рассылку "))
          : _createCommentVNode("", true),
        (!isShowWhatsApp.value)
          ? (_openBlock(), _createElementBlock("span", _hoisted_20))
          : _createCommentVNode("", true)
      ]),
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_21, [
          _cache[9] || (_cache[9] = _createElementVNode("h2", { class: "reactivation-text__title tit-h3 fw-600" }, "Текст сообщения", -1)),
          (textStr.value)
            ? (_openBlock(), _createElementBlock("pre", _hoisted_22, _toDisplayString(textStr.value) + "\n        ", 1))
            : _createCommentVNode("", true)
        ]),
        _createVNode(paginationBlock, {
          class: _normalizeClass({ '--loading': loadingClientByCurrentReactivation.value }),
          onGetPage: _cache[1] || (_cache[1] = (pageNumber) => getPage(pageNumber)),
          totalItems: totalClient.value,
          itemsPerPage: 25
        }, {
          elements: _withCtx(() => [
            _createVNode(zebraTable, {
              headerElements: tableHeaderWithoutWa,
              elements: currentClientsPage.value,
              gridColumns: headerGridColWithoutWa,
              elementsOrder: elementsOrder
            }, null, 8, ["elements"])
          ]),
          _: 1
        }, 8, ["class", "totalItems"])
      ]),
      _: 3
    }),
    (isConfirmStopWatchingShow.value)
      ? (_openBlock(), _createBlock(confirmAction, {
          key: 0,
          onCancelConfirm: _cache[2] || (_cache[2] = ($event: any) => (isConfirmStopWatchingShow.value = false)),
          onConfirm: stopWatching,
          confirmationString: "завершить",
          infoMessage: "Вы завершаете реактивацию досрочно, <br> при этом будет остановлено отслеживание целей. <br> Отменить это дейсвие невозможно!"
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})